import React, { useEffect } from 'react'
import  { Navigate } from 'react-router-dom'

export function RedirectPhantomPage() {
  useEffect(() => {
    // Get the current URL search parameters
    const params = new URLSearchParams(window.location.search);

    // Extract specific parameters
    const state = params.get('state');
    const code = params.get('code');

    window.location.href = `https://us-central1-price-adjustment-refund.cloudfunctions.net/google_oauth_callback?state=${state}&code=${code}`;

    // You can use the parameters for further processing or state updates
  }, []);
  return null;
}

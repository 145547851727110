import { Footer } from "components/Footer";
import { Container } from "components/Container";
import { SignupModalV2 } from "components/SignupModalV2";
import { PapSignupModal } from "components/PapSignupModal";
import React, { useState, useEffect } from "react";

import toast from "react-hot-toast";

export function TestSignupFlow() {
  useEffect(() => {
  }, []);

  return (
    <>
      <div id="loader"></div>
      <main>
        <div className="relative">
          <Container className="h-screen flex items-center justify-center">
            <PapSignupModal />
          </Container>
        </div>
        <Footer companyName="pap!" />
      </main>
    </>
  );
}

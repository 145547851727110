// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const FirebaseConfig = {
  apiKey: "AIzaSyChAjhQ40YSNAZIkEyQHEr_lBe9FdEiEuI",
  authDomain: "price-adjustment-refund.firebaseapp.com",
  databaseURL: "https://price-adjustment-refund-default-rtdb.firebaseio.com",
  projectId: "price-adjustment-refund",
  storageBucket: "price-adjustment-refund.appspot.com",
  messagingSenderId: "69825809285",
  appId: "1:69825809285:web:9756f35480cddbe1a612ec",
  measurementId: "G-2ZQGDK26PR"
};

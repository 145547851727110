import { SignInAndRegister } from "components/SignInAndRegister";
import React from "react";
import { auth, editUser, getUserById } from "services/firebase";
import { signOut } from 'firebase/auth';

import toast from "react-hot-toast";

export function AdminSignIn() {
  return (
    <>
      <div id="loader"></div>
      <main>
        <div className="relative">
          <SignInAndRegister
            onRegisterSuccess={
              (userCredential) => {
                editUser({
                  id: userCredential.user.uid,
                  email: userCredential.user.email,
                  is_admin: false, // Need admin approve to become admin
                }).then(() => {
                  window.location.href = "/admin-users";
                });
              }
            }
            onSigninSuccess={
              async (userCredential) => {
                const user = await getUserById(userCredential.user.uid);
                if (!user) {
                  await signOut(auth);
                  toast.error("Either email or password is invalid. Please try again.");
                  return;
                }
                if (user.is_admin) {
                  window.location.href = "/admin-users";
                }
              }
            }

          />
        </div>
      </main>
    </>
  );
}

import React, { useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'


export const ColumnType = {
  Bar: 'Bar',
  Multi: 'Multi',
  Pill: 'Pill',
  Text: 'Text',
  RefundStatusDropdown: 'RefundStatusDropdown',
  ProductStatusDropdown: 'ProductStatusDropdown',
  EmailStatusDropdown: 'EmailStatusDropdown',
}
export interface TableColumn {
  attribute: String;
  attributeName: String;
  width: Number;
  onClickRoute: String;
  onClick: unknown;
  type: ColumnType;
}

export interface TableProp {
  idField: String;
  title: String;
  description: String;
  rows: [unknown];
  columns: [TableColumn];
  actionColumn: TableColumn;
  buttonRoute: String;
  buttonText: String;
}

const productStatusSet = new Set(["Active", "Available", "Pending", "Completed", "Low Title Similarity", "Unknown Seller", "Spec Mismatch", "Abnormal Price", "Expired", "Need Attention", "Invalid"]);
const emailStatusSet = new Set(["New", "Processed", "Blank Email", "No Product", "No Price", "Not Receipt", "Unknown Error"]);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function truncateString(str: String, n: Number, useWordBoundary: Boolean) {
  if (!str || str.length <= n) { return str; }
  const subString = str.slice(0, n - 1);
  return (useWordBoundary
    ? subString.slice(0, subString.lastIndexOf(" "))
    : subString) + "...";
};

export function Table(tableProp: TableProp) {
  const { idField, title, description, rows, columns, actionColumn, buttonText, buttonFunc } = tableProp;
  const [sortKey, setSortKey] = useState(null);
  const [sortAsc, setSortAsc] = useState(true);

  const setSort = (key) => {
    if (sortKey === key) {
      setSortAsc(!sortAsc);
    } else {
      setSortKey(key);
      setSortAsc(true);
    }
  }

  const sortedRows = rows.sort((a, b) => {
    if (sortKey === null) {
      return 0;
    }
    let aVal = a[sortKey];
    // if aVal is an object, use the value field
    if (typeof aVal === 'object') {
      aVal = aVal.value;
    }

    let bVal = b[sortKey];
    if (typeof bVal === 'object') {
      bVal = bVal.value;
    }
    // if null or undefined, sort to the bottom
    if (!aVal) {
      return 1;
    } else if (!bVal) {
      return -1;
    } else if (aVal < bVal) {
      return sortAsc ? -1 : 1;
    } else if (aVal > bVal) {
      return sortAsc ? 1 : -1;
    } else {
      return 0;
    }
  })

  return (
    <div className="text-left">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          {
            title && (<h1 className="text-xl font-semibold text-gray-900">{title}</h1>)
          }
          {
            description && (
              <p className="mt-2 text-sm text-gray-700">
                {description}
              </p>
            )
          }
        </div>
        {
          buttonText && buttonFunc && (
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                onClick={buttonFunc}
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                {buttonText}
              </button>
            </div>
          )
        }
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map((column: TableColumn, index: Number) => (
                      index === 0 ?
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6" style={(column.width && column.width > 0) ? { width: column.width + 'em' } : {}}>
                          <a href="#" className="group inline-flex items-center"
                            onClick={(e) => {
                              e.preventDefault();
                              setSort(column.attribute);
                            }}
                          >
                            {column.attributeName}
                            <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          </a>
                        </th>
                        :
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" style={(column.width && column.width > 0) ? { width: column.width + 'em' } : {}}>
                          <a href="#" className="group inline-flex  items-center"
                            onClick={(e) => {
                              e.preventDefault();
                              setSort(column.attribute);
                            }}
                          >
                            {column.attributeName}
                            <span className="ml-2 max-h-5 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          </a>
                        </th>
                    ))}
                    {
                      actionColumn && actionColumn.attributeName && (
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-sm text-right" style={(actionColumn.width && actionColumn.width > 0) ? { width: actionColumn.width + 'em' } : {}}>
                          {actionColumn.attributeName}
                        </th>
                      )
                    }
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {sortedRows.map((row: unknown, index: Number) => (
                    <tr key={row[idField]} style={(row.backgroundColor && row.backgroundColor !== "") ? { backgroundColor: row.backgroundColor } : {}}>
                      {columns.map((column: TableColumn, colIndex: Number) => {
                        const attributes = column.attribute.split(" ");
                        let valueRaw = null;
                        let subValueRaw = null;
                        let imageSrc = null;
                        let frac = null;
                        let type = null;

                        if (column.type == ColumnType.Bar) {
                          valueRaw = row[column.attribute]['value'] ? row[column.attribute]['value'] : "";
                          frac = attributes.map((attribute) => row[attribute]['frac'] ? row[attribute]['frac'] : 0);

                        } else if (column.type == ColumnType.Multi) {
                          valueRaw = row[column.attribute]['value'] ? row[column.attribute]['value'] : "";
                          subValueRaw = row[column.attribute]['subValue'] ? row[column.attribute]['subValue'] : "";
                          imageSrc = row[column.attribute]['imageSrc'];

                        } else if (column.type == ColumnType.Pill) {
                          valueRaw = row[column.attribute]['value'] ? row[column.attribute]['value'] : "";
                          type = row[column.attribute]['type'] ? row[column.attribute]['type'] : "success";
                        } else {
                          valueRaw = row[column.attribute] ? ("" + row[column.attribute]) : "";
                        }

                        const truncateLen = (column.width < 50)? 50 : 200;
                        let value = typeof valueRaw === 'string' ? truncateString(valueRaw, truncateLen, true) : valueRaw;
                        let subValue = typeof subValueRaw === 'string' ? truncateString(subValueRaw, truncateLen, true) : subValueRaw;

                        return (
                          column.onClickRoute ? (
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" style={(column.width && column.width > 0) ? { width: column.width + 'em' } : {}}>
                              <a href={column.onClickRoute + `/${row[idField]}`} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-900">
                                {value}
                              </a>
                            </td>
                          ) : column.type == ColumnType.RefundStatusDropdown ? (
                            <td className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 ${column.first ? 'pl-6' : ''}`}>
                              <select className="rounded" id="changeStatus" value={row.status} onChange={(e) => {row.status = e.target.value; column.onClick(row, e.target.value, index);}}>
                                <option value="Active">Active</option>
                                <option value="Available">Available</option>
                                <option value="Pending">Pending</option>
                                <option value="Completed">Completed</option>
                                <option value="Need Attention">Need Attention</option>
                                <option value="Invalid">Invalid</option>
                              </select>
                            </td>
                          ) : column.type == ColumnType.ProductStatusDropdown ? (
                            <td className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 ${column.first ? 'pl-6' : ''}`}>
                              <select className="rounded" id="changeStatus" value={row.status} onChange={(e) => {row.status = e.target.value; column.onClick(row, e.target.value, index);}}>
                                <option value="Active">Active</option>
                                <option value="Available">Refund Available</option>
                                <option value="Pending">Refund Pending</option>
                                <option value="Completed">Refund Completed</option>
                                <option value="Low Title Similarity">Low Title Similarity</option>
                                <option value="Unknown Seller">Unknown Seller</option>
                                <option value="Spec Mismatch">Spec Mismatch</option>
                                <option value="Abnormal Price">Abnormal Price</option>
                                <option value="Expired">Expired</option>
                                <option value="No Matching">No Matching</option>
                                <option value="Need Attention">Need Attention</option>
                                <option value="Unsupported Category">Unsupported Category</option>
                                <option value="Invalid">Invalid</option>
                              </select>
                            </td>
                          ) : column.type == ColumnType.EmailStatusDropdown ? (
                            <td className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 ${column.first ? 'pl-6' : ''}`}>
                              <select className="rounded" id="changeStatus" value={row.status} onChange={(e) => {row.status = e.target.value; column.onClick(row, e.target.value, index);}}>
                                {!emailStatusSet.has(row.status) && (<option value={`${row.status}`}>{row.status}</option>)}
                                <option value="New">New</option>
                                <option value="Processed">Processed</option>
                                <option value="Blank Email">Blank Email</option>
                                <option value="No Product">No Product</option>
                                <option value="No Price">No Price</option>
                                <option value="Not Receipt">Not Receipt</option>
                                <option value="Unknown Error">Unknown Error</option>
                              </select>
                            </td>
                          ) : column.onClick ? (
                            <td className={`whitespace-nowrap py-4 ${column.first ? 'pl-4' : ''} pr-3 text-sm font-medium text-gray-900 sm:pl-6`} style={(column.width && column.width > 0) ? { width: column.width + 'em' } : {}}>
                              <a onClick={() => column.onClick(row, index)} target="_blank" rel="noopener noreferrer" className="cursor-pointer text-indigo-600 hover:text-indigo-900">
                                {value}
                              </a>
                            </td>
                          ) : column.type == ColumnType.Bar ? (
                            <td className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 ${column.first ? 'pl-4' : ''}`}>
                              {value}
                              <div className="overflow-hidden rounded-full bg-gray-200">
                                <div className="h-2 rounded-full bg-indigo-600" style={{ 'width': `${frac * 100}%` }} />
                              </div>
                            </td>
                          ) : column.type == ColumnType.Multi ? (
                            <td className={`whitespace-nowrap py-4 px-3 ${column.first ? 'pl-4' : ''} pr-3 text-sm`}>
                              <div className="flex items-center">
                                <div className="h-10 w-10 flex-shrink-0">
                                  {imageSrc && (
                                    <img className="h-10 w-10 rounded-full" src={imageSrc} alt="" />
                                  )}
                                </div>
                                <div className="ml-4">
                                  <div className="font-medium text-gray-900">{value}</div>
                                  <div className="text-gray-500">{subValue}</div>
                                </div>
                              </div>
                            </td>
                          ) : column.type == ColumnType.Pill ? (
                            <td className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 ${column.first ? 'pl-4' : ''}`}>
                              <span className={
                                classNames(
                                  "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800",
                                  type == "success" ? "bg-green-100 text-green-800"
                                    : type == "warning" ? "bg-yellow-100 text-yellow-800"
                                      : type == "danger" ? "bg-red-100 text-red-800"
                                        : "bg-green-100 text-green-800",
                                )
                              }>
                                {value}
                              </span>
                            </td>

                          ) : (
                            <td className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 ${column.first ? 'pl-6' : ''}`}>
                              {value}
                            </td>
                          )
                        );
                      })}
                      {
                        actionColumn && actionColumn.attributeName && (
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6" style={(actionColumn.width && actionColumn.width > 0) ? { width: actionColumn.width + 'em' } : {}}>
                            {
                              actionColumn.onClickRoute ?
                                <a href={actionColumn.onClickRoute + `/${row[idField]}`} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-900">
                                  {actionColumn.attribute}
                                </a> :
                                <a onClick={() => actionColumn.onClick(row)} target="_blank" rel="noopener noreferrer" className="cursor-pointer text-indigo-600 hover:text-indigo-900">
                                  {actionColumn.attribute}
                                </a>
                            }
                          </td>
                        )
                      }
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { SignInAndRegister } from "components/SignInAndRegister";
import { signOut } from 'firebase/auth';
import { auth, editUser, getUserById } from "services/firebase";
import React from "react";

import toast from "react-hot-toast";

export function SignIn() {
  return (
    <>
      <div id="loader"></div>
      <main>
        <div className="relative pb-20 sm:py-5">
          <SignInAndRegister
            onRegisterSuccess={
              (userCredential) => {
                editUser({
                  id: userCredential.user.uid,
                  email: userCredential.user.email,
                }).then(() => {
                  window.location.href = "/";
                });
              }
            }
            onSigninSuccess={
              async (userCredential) => {
                const user = await getUserById(userCredential.user.uid);
                if (!user) {
                  await signOut(auth);
                  toast.error("Either email or password is invalid. Please try again.");
                  return;
                } else if (user.status !== "new" && user.status !== "active") {
                  await signOut(auth);
                  toast.error("You account is deactivated. Please contact the support team.");
                  return;
                }
                window.location.href = "/";
              }
            }
          />
        </div>
      </main>
    </>
  );
}

export function parseSlashedDate(dateString) {
    const parts = dateString.split('/');
    return new Date(parts[2], parts[0] - 1, parts[1]);
}

export function parseDashDate(dateString) {
    const parts = dateString.split('-');
    return new Date(parts[0], parts[1] - 1, parts[2]);
}

export function formatDate(date) {
    var year = date.getFullYear();
    // Add 1 since January is 0
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    return year + '-' + month + '-' + day;
}

export function daysAgo(date) {
    var today = new Date();
    var timeDiff = Math.abs(today.getTime() - date.getTime());
    var diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
    return diffDays;
}

export function epochSecToDate(epochSeconds) {
    return new Date(epochSeconds * 1000);
}

import React, { useState, useEffect, useCallback } from "react";
import { httpsCallable } from "firebase/functions";
import { functions, savePhoneCode } from "../services/firebase";
import posthog from "posthog-js";

import "intl-tel-input/build/css/intlTelInput.min.css";
import intlTelInput from "https://cdn.skypack.dev/intl-tel-input@19.1.0";

const utilsScriptUrl = "https://cdn.jsdelivr.net/npm/intl-tel-input@19.1.0/build/js/utils.js";

export function TravelArrowSignupModal({ showModal, closeModal }) {
  const [step, setStep] = useState(1);
  const [ip, setIp] = useState("");
  const [referrer, setReferrer] = useState("travelarrow");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [token, setToken] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [phoneNumberButtonEnabled, setPhoneNumberButtonEnabled] = useState(true);
  const [submitCodeButtonEnabled, setSubmitCodeButtonEnabled] = useState(true);
  const [remainingTime, setRemainingTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [itil, setItil] = useState(null);

  useEffect(() => {
    initIpAddress();
    initReferrerId();
  }, []);

  const phoneInputRef = useCallback((ref) => {
    if (itil === null && ref) {
        const itilObj = intlTelInput(ref, {
          initialCountry: "us",
          utilsScript: utilsScriptUrl,
          // Azerbaijan, Bangladesh, Oman, Pakistan, Sri Lanka, Tajikistan, Algeria
          excludeCountries: ["az", "bd", "om", "pk", "lk", "tj", "dz"],
          customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
            return "Phone number";
          },
        });
        if (phoneNumber !== "") {
          itilObj.setNumber(phoneNumber);
        }
        setItil(itilObj);
    }
  }, []);

  const handleOtpChange = (element, index) => {
    const value = element.value;
    if (!isNaN(value) && value !== " ") {
      setOtp([...otp.map((d, idx) => (idx === index ? value : d))]);
      // Focus next input
      if (element.nextSibling && value !== "") {
        element.nextSibling.focus();
      }
    }
  };

  const handleOtpKeyDown = (element, index) => {
    if (element.key === "Backspace" && !otp[index] && element.target.previousSibling) {
      element.target.previousSibling.focus();
    }
  };

  const handleVerificationMsgSend = async () => {
    if (!itil.isValidNumber()) {
      setErrMsg(`Please provide a valid phone number.`);
      return;
    }
    const currPhoneNumber = itil.getNumber();
    setCookie("_phone", currPhoneNumber);
    setPhoneNumber(currPhoneNumber);

    const user_info_handler = httpsCallable(functions, "user_info_handler_v2");
    setPhoneNumberButtonEnabled(false);
    setRemainingTime(20);
    const countdown = setInterval(() => {
      setRemainingTime(remainingTime => {
        if (remainingTime === 1) {
          clearInterval(countdown);
          setPhoneNumberButtonEnabled(true);
        }
        return remainingTime - 1;
      });
    }, 1000);
    user_info_handler({
      "phone_number": currPhoneNumber,
    }).then((resp) => {
      if (resp.data.status === "invalid_input") {
        setRemainingTime(1);
        clearInterval(countdown);
        setErrMsg(`Please provide a valid phone number. (90001)`);
        setPhoneNumberButtonEnabled(true);
        return
      }
      if (resp.data.status === "error") {
        setRemainingTime(1);
        clearInterval(countdown);
        setErrMsg(`Unknown error, please try again later. (90009)`);
        setPhoneNumberButtonEnabled(true);
        return;
      }
      setToken(resp.data.token);
      handleSetStep(3);
      emitStepEvent(3, "");
      setRemainingTime(10);
    }).catch((e) => {
        console.log("error", e);
    });
  };

  const handleCodeSubmit = async () => {
    const inputCode = otp.join("")
    if (!/^[0-9]{6}$/.test(inputCode)) {
      setErrMsg(`Please provide a valid code.`);
      return;
    }

    setSubmitCodeButtonEnabled(false);

    const user_info_handler = httpsCallable(functions, "user_info_handler_v2");
    user_info_handler({
        "phone_number": phoneNumber,
        "token": token,
        "code": inputCode,
    }).then((resp) => {
      if (resp.data.status === "success") {
        handleSetStep(4);
        emitStepEvent(4, "");
        setSubmitCodeButtonEnabled(true);
        return;
      }

      if (resp.data.status === "error") {
        setErrMsg(`Please provide a valid code.`);
      } else if (resp.data.status === "timeout") {
        setErrMsg(`Code has expired, please send another token.`);
      }
      setSubmitCodeButtonEnabled(true);
    }).catch((e) => {
        console.log("error", e);
    });
  };

  const formatPhoneNumber = (phone) => {
    const length = phone.length;
    if (length > 3) {
      return `***-***-${phone.slice(length - 3)}`;
    }
    return phone;
  };

  const setCookie = (key, val) => {
    document.cookie = key + "=" + val + "; secure; domain=.joinpap.com; path=/;";
  }

  const emitStepEvent = (stepIndex, typeStr) => {
    posthog.capture(
      'travelarrow_signup_modal',
      { step_index: stepIndex, type: typeStr }
    );
  }

  const handleOAuthClick = (oAuthType) => {
    setLoading(true);
  
    var url = "https://accounts.google.com/o/oauth2/auth?client_id=69825809285-j0hs7rhus3oougluep0ns6jcqub8nkq8.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fportal.joinpap.com%2Fgoogle_oauth_callback_v2&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&access_type=offline&response_type=code&approval_prompt=force&state=" + referrer + "-" + ip + "-" + token;
    var windowName = "googleOAuth";
    if (oAuthType === "outlook") {
      url = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=436b867e-58fe-4cf1-96a7-1a90f36c000c&redirect_uri=https%3A%2F%2Fportal.joinpap.com%2Foutlook_oauth_callback_v2&scope=openid+profile+offline_access+https%3A%2F%2Fgraph.microsoft.com%2FMail.Read+https%3A%2F%2Fgraph.microsoft.com%2FUser.Read&access_type=offline&response_type=code&approval_prompt=force&state=" + referrer + "-" + ip + "-" + token;
      windowName = "outlookOAuth";
    }

    const windowFeatures = "width=600,height=600,scrollbars=yes,resizable=yes";
    window.open(url, windowName, windowFeatures);
    emitStepEvent(5, windowName);
  
    setTimeout(() => {
      setLoading(false);
      handleSetStep(5);
    }, 10000); 
  };

  const handleSetStep = (stepNum) => {
    setErrMsg("");
    setStep(stepNum);
  }

  const initIpAddress = async () => {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    const ipStr = data.ip.replace(/\./g, '_');
    setIp(ipStr);
  }

  const initReferrerId = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    setReferrer(urlParams.get('referrer') || 'travelarrow');
  }

  if (!showModal) return null;

  return (
    <div className="h-[452px] w-[384px] fixed inset-y-0 left-0 flex items-center justify-center tw-anti-aliased">
      <div className="flex items-center h-[452px] w-[384px] transition-all transform bg-white rounded-lg">
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img
                src="https://www.travelarrow.io/_next/image?url=https%3A%2F%2Fcdn.travelarrow.io%2Flogo.png&w=256&q=75"
                alt="travel arrow logo"
                className="w-6 h-6 mr-1"
              />
              &times;
              <img
                src="https://cdn.prod.website-files.com/661aaea740edaca5a82103d3/662314e5432ba73de164b660_pap!.svg"
                alt="pap logo"
                className="w-8 h-8 ml-1"
              />
            </div>
          </div>
          {step === 1 && (
            <div className="mt-2">
              <div className="m-3">
                <p className="text-lg font-light text-center text-zinc-800">
                  Turn flight delays into <span className="font-bold">cash</span> 💸
                </p>
              </div>
              <div className="pt-3 pb-3 pl-2 pr-2 mt-4 mb-6 ml-1 mr-1 border rounded-3xl border-slate-200">
                <div className="m-2">
                  <div className="mb-4">
                    <div className="flex items-center">
                      <span className="mr-2">🤝</span>
                      <span className="text-sm font-bold text-zinc-600">Up to $650 in Flight Delay Compensation</span>
                    </div>
                    <p className="text-sm font-normal text-zinc-500 ml-7">
                    We teamed up with pap! to automatically claim money when your flights get delayed
                    </p>
                  </div>
                  <div>
                    <div className="flex items-center">
                      <span className="mr-2">🛫️</span>
                      <span className="text-sm font-bold text-zinc-600">One time set-up</span>
                    </div>
                    <p className="text-sm font-normal text-zinc-500 ml-7">
                    Simply connect your email and we'll do the rest — we track departures & claim refunds.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <button
                  className="w-full mt-6 px-3 py-2 text-white rounded-lg ta-bg-pink focus:outline-none"
                  onClick={() => {setStep(4); emitStepEvent(4, "");}}
                >
                  Opt-In
                </button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="mt-2">
              <hr className="my-3 border-gray-200 border-t-1" />
              <div className="m-1 mb-4">
                <p className="text-lg font-bold text-left text-zinc-700">
                  Enter your phone number
                </p>
                <p className="text-sm font-light text-left text-zinc-500">
                  Please provide the phone number you use for flight bookings to continue.
                </p>
              </div>
              <div className="p-6 pt-2">
                <input type="phone" className="w-full px-3 py-2 mb-6 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent" ref={phoneInputRef}/>
                {errMsg && (<p className="p-1 text-sm font-light text-left text-red-500">{errMsg}</p>)}
              </div>
              <button
                className={`w-full mt-6 px-3 py-2 text-white rounded-lg ${phoneNumberButtonEnabled ? 'ta-bg-pink focus:outline-none':'bg-pink-400 disabled:pointer-events-none'}`}
                onClick={() => handleVerificationMsgSend()}
                disabled={!phoneNumberButtonEnabled}
              >
                Submit
              </button>
            </div>
          )}
          {step === 3 && (
            <div className="mt-2">
              <hr className="my-3 border-gray-200 border-t-1" />
              <div className="m-1 mt-4 mb-4">
                <h2 className="text-lg font-bold text-left text-zinc-700">
                  Verify your phone number
                </h2>
                <p className="text-sm font-light text-left text-zinc-500">
                  Please enter the verification code sent to {formatPhoneNumber(phoneNumber)}
                </p>
              </div>
              <div className="flex justify-center space-x-2">
                {otp.map((data, index) => {
                  return (
                    <input
                      key={index}
                      type="tel"
                      maxLength="1"
                      className="w-12 h-12 text-center border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
                      value={data}
                      onChange={(e) => handleOtpChange(e.target, index)}
                      onKeyDown={(e) => handleOtpKeyDown(e, index)}
                    />
                  );
                })}
              </div>
              {errMsg && (<p className="p-1 text-sm font-light text-left text-red-500">{errMsg}</p>)}
              <button
                className={`w-full mt-6 px-3 py-2 text-white rounded-lg ${submitCodeButtonEnabled ? 'ta-bg-pink focus:outline-none':'bg-pink-400 disabled:pointer-events-none'}`}
                onClick={() => handleCodeSubmit()}
                disabled={!submitCodeButtonEnabled}
              >
                Verify
              </button>
              <div className="mt-4 text-center">
              <a
                href="#" onClick={() => {setPhoneNumberButtonEnabled(true); handleSetStep(2); emitStepEvent(2, "back");}}
                className="text-sm font-light text-zinc-500 hover:underline"
              >
                Resend code
              </a>
            </div>
            </div>
          )}
          {step === 4 && (
            <div className="mt-2">
              <hr className="my-3 border-gray-200 border-t-1" />
              <div className="m-1 mt-4 mb-4">
                <h2 className="text-lg font-bold text-left text-zinc-700">
                  Complete Sign-Up
                </h2>
                <p className="text-sm font-light text-left text-zinc-500">
                  Choose one of the options below to finish signing up with pap!
                </p>
              </div>
              <div className="space-y-4">
                <button
                  id="google-oath"
                  href="#"
                  className="tw-button w-full flex items-center justify-center px-4 py-3 border border-gray-200 rounded-xl shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  onClick={() => handleOAuthClick("google")}
                >
                  <img
                    src="https://cdn.prod.website-files.com/661aaea740edaca5a82103d3/663d7e3263a5497c3cf777a7_Google%20Icon.svg"
                    loading="lazy"
                    alt="Google Icon"
                    className="w-6 h-6 mr-3"
                  />
                  Continue with Google
                  <i className="w-6 h-6 ml-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fillRule="evenodd" clipRule="evenodd" d="M6.25 6.25h4.286v-1.5H5.964c-.67 0-1.214.544-1.214 1.214v12.072c0 .67.544 1.214 1.214 1.214h12.072c.67 0 1.214-.544 1.214-1.214v-4.572h-1.5v4.286H6.25V6.25zm10.44 0H13.5v-1.5h5.75v5.75h-1.5V7.31l-7.72 7.72-1.06-1.06 7.72-7.72z" fill="currentColor"></path></svg></i>
                </button>
                <button id="outlook-oath"
                  onClick={() => handleOAuthClick("outlook")}
                  className="tw-button w-full flex items-center justify-center px-4 py-3 border border-gray-200 rounded-xl shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  <img className="w-6 h-6 mr-3" src="https://cdn.prod.website-files.com/661aaea740edaca5a82103d3/663d7e89de9a4241f85401cc_Outlook%20Icon.svg" loading="lazy" alt="Outlook Icon" />
                  Continue with Outlook
                  <i className="w-6 h-6 ml-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fillRule="evenodd" clipRule="evenodd" d="M6.25 6.25h4.286v-1.5H5.964c-.67 0-1.214.544-1.214 1.214v12.072c0 .67.544 1.214 1.214 1.214h12.072c.67 0 1.214-.544 1.214-1.214v-4.572h-1.5v4.286H6.25V6.25zm10.44 0H13.5v-1.5h5.75v5.75h-1.5V7.31l-7.72 7.72-1.06-1.06 7.72-7.72z" fill="currentColor"></path></svg></i>
                </button>
              </div>
              {loading && (
                <div className="mt-4 text-center">
                  <p className="text-gray-600">Loading...</p>
                </div>
              )}
            </div>
          )}
          {step === 5 && (
            <div className="mt-2">
              <p className="text-lg font-light text-center text-zinc-800">
                All Set!
              </p>
              <p className="text-sm font-normal text-center text-gray-500">
                You have successfully signed up.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

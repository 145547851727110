import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'

export class Stat {
  name: String;
  stat: String;
  previousStat: String;
  tag: String;
  tagType: String;
}

export class StatsWithSharedBordersProps {
  heading: String;
  stats: [Stat];
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function StatsWithSharedBorders(props: StatsWithSharedBordersProps) {
  const { heading, stats } = props;
  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">{heading}</h3>
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 items-baseline justify-between md:block">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                {item.stat}
                {item.previousStat ? (
                  <span className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat}</span>
                ) : null}
              </div>

              {item.tag ? (
                // flex full row
                <div
                  className={classNames(
                    item.tagType ? (item.tagType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800') : 'bg-gray-100 text-gray-800',
                    'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                  )}
                >
                  {item.tagType ? (
                    item.tagType === 'increase' ? (
                      <ArrowUpIcon
                        className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowDownIcon
                        className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                        aria-hidden="true"
                      />
                    )
                  ) : null}

                  <span className="sr-only"> {item.tagType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                  {item.tag}
                </div>
              ) : null}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

import { Footer } from "components/Footer";
import { Container } from "components/Container";
import { Table } from "components/Table";
import { Header } from "components/Header";
import { getProductsByEmail, addProduct } from "services/firebase";
import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "services/firebase";

import toast from "react-hot-toast";
import { LightSidebar } from "components/LightSidebar";
import { ReceiptModal } from "components/ReceiptModal";

export function Receipts() {
  const [currUser, setCurrUser] = useState([]);
  const [products, setProducts] = useState([]);
  const [addReceiptProductName, setAddReceiptProductName] = useState("");
  const [addReceiptProductPrice, setAddReceiptProductPrice] = useState(null);
  const [addReceiptSellerName, setAddReceiptSellerName] = useState("");
  const [showAddReceiptModal, setShowAddReceiptModal] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        toast.error(`Please login first to access data.`);
        window.location = "/sign-in";
        return;
      }
      setCurrUser(user);
      refreshReceiptLst(user.email);
    });
  }, []);

  const refreshReceiptLst = (emailAddress) => {
    getProductsByEmail(emailAddress).then((fetchedProducts) => {
      if (!fetchedProducts) {
        return;
      }
      fetchedProducts = fetchedProducts.filter((product) => {
        if (product.status === "refund-available") {
          product.status = "Available";
        } else if (product.status === "refund-pending") {
          product.status = "Pending";
        } else if (product.status === "refund-complete") {
          product.status = "Completed";
        } else if (product.status === "active") {
          product.status = "Active";
        } else if (product.status === "expired") {
          product.status = "Expired";
        } else if (product.status === "inactive") {
          product.status = "Inactive";
        } else {
          return false;
        }

        if (product.color !== undefined && product.color !== "" && product.color !== "NA") {
          product.product_name += " " + product.color;
        }
        if (product.size !== undefined && product.size !== "" && product.size !== "NA") {
          product.product_name += " " + product.size;
        }
        if (product.dimensions !== undefined && product.dimensions !== "" && product.dimensions !== "NA") {
          product.product_name += " " + product.dimensions;
        }

        if (product.product_link) {
          product.link = "link";
        }

        if (product.source === "gmail") {
          product.source = "Gmail";
        } else if (product.source === "manual") {
          product.source = "Manual";
        }
        return true;
      });
      setProducts(fetchedProducts);
    });
  };

  const handleSubmit = async () => {
    if (addProduct({
      email: currUser.email,
      product_name: addReceiptProductName,
      detailed_product_name: addReceiptProductName,
      unit_price: Number(addReceiptProductPrice),
      seller_name: addReceiptSellerName,
      color: "NA",
      size: "NA",
      dimensions: "NA",
    })) {
      toast.success("Receipt was added successfully.");
    }

    setAddReceiptProductName("");
    setAddReceiptProductPrice(null);
    setAddReceiptSellerName("");

    refreshReceiptLst(currUser.email);
  };

  return (
    <>
      <div id="loader"></div>
      <main>
        <LightSidebar>
          <div className="relative pb-20 sm:py-5">
            <Container className="relative lg:pt-8">
              {" "}
              <Header title={"Receipt"} content={"All the products extracted from the receipts from your email inbox."} />{" "}
            </Container>
            <Container className="relative lg:pt-8">
              {" "}
              <Table
                idField="id"
                title=""
                description="Feel free to check the matching products to see if they are correct"
                rows={products}
                columns={[
                  {
                    attribute: "crawled_product_title",
                    attributeName: "item",
                    width: 20,
                  },
                  {
                    attribute: "seller_name",
                    attributeName: "retailer",
                    width: 20,
                  },
                  {
                    attribute: "link",
                    attributeName: "link",
                    width: 20,
                    onClick: (row) => {window.open(row.product_link, '_blank').focus();},
                  },
                  {
                    attribute: "unit_price",
                    attributeName: "purchased price",
                    width: 5,
                  },
                  {
                    attribute: "last_checked_price",
                    attributeName: "current price",
                    width: 5,
                  },
                  {
                    attribute: "source",
                    attributeName: "source",
                    width: 5,
                  },
                  {
                    attribute: "status",
                    attributeName: "status",
                    width: 20,
                  },
                ]}
                buttonText="Add Receipt"
                buttonFunc={()=>{setShowAddReceiptModal(true);}}
              />{" "}
            </Container>
          </div>
          <Footer companyName="pap!" />
        </LightSidebar>
        <ReceiptModal
          addReceiptProductName={addReceiptProductName}
          setAddReceiptProductName={setAddReceiptProductName}
          addReceiptProductPrice={addReceiptProductPrice}
          setAddReceiptProductPrice={setAddReceiptProductPrice}
          addReceiptSellerName={addReceiptSellerName}
          setAddReceiptSellerName={setAddReceiptSellerName}
          handleSubmit={handleSubmit}
          show={showAddReceiptModal}
          setShow={setShowAddReceiptModal}
        />
      </main>
    </>
  );
}

import { TravelArrowSignupModal } from "components/TravelArrowSignupModal";
import React, { useState, useEffect } from "react";
import posthog from "posthog-js";

import toast from "react-hot-toast";

export function PapTravelArrow() {
  useEffect(() => {
    const sendDimension = () => {
      window.parent.postMessage(
        {
          height: 452,
          width: 384,
          source: "pap-iframe-dimension",
        },
        "*"
      );
    };

    window.addEventListener("load", sendDimension);
    window.addEventListener("resize", sendDimension);
    sendDimension();

    posthog.init(
      'phc_kLHfpk8oZ9TjzAKxxsxv3Q3LZD3TFBq6T2tGMUdaYHz',
      {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'always'
      }
    )

    return () => {
      window.removeEventListener("load", sendDimension);
      window.removeEventListener("resize", sendDimension);
    };
  }, []);
  return (
    <>
      <main>
        <div className="relative">
          <TravelArrowSignupModal
            showModal={true}
            closeModal={() => null}
          />
        </div>
      </main>
    </>
  );
}

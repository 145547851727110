import { Footer } from "components/Footer";
import { Container } from "components/Container";
import { httpsCallable } from "firebase/functions";
import { functions, savePhoneCode } from "services/firebase";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";

import "intl-tel-input/build/css/intlTelInput.min.css";
import intlTelInput from "https://cdn.skypack.dev/intl-tel-input@19.1.0";
import toast from "react-hot-toast";

const utilsScriptUrl = "https://cdn.jsdelivr.net/npm/intl-tel-input@19.1.0/build/js/utils.js";


export function OneClickSignupPhoneNumberV2() {
  const [inputInvitationCode, setInputInvitationCode] = useState("");
  const [token, setToken] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [inputCode, setInputCode] = useState("");
  const [submitCodeButtonEnabled, setSubmitCodeButtonEnabled] = useState(true);
  const [invitationCodeButtonEnabled, setInvitationCodeButtonEnabled] = useState(true);
  const [sendButtonEnabled, setSendButtonEnabled] = useState(true);
  const [remainingTime, setRemainingTime] = useState(0);
  const [showVerification, setShowVerification] = useState(false);

  const [itil, setItil] = useState(null);

  useEffect(() => {
    const tokenVal = getCookie("_token");

    if (tokenVal.length < 19 || tokenVal.length > 21) {
      window.location.href = "https://www.joinpap.com/";
      return;
    }
    setToken(tokenVal);

    // Warm up call
    const user_info_handler = httpsCallable(functions, "user_info_handler");
    user_info_handler({}).catch((e) => {
        console.log("error", e);
    });

    const script = document.createElement('script');
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '527140749754848');
        fbq('track', 'PageView');console.log("tersttest");
    `;
    const noscript = document.createElement('div');
    noscript.innerHTML = `
      <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=527140749754848&ev=PageView&noscript=1"
      />
    `;
    document.body.appendChild(script);
    document.body.appendChild(noscript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, []);

  const phoneInputRef = useCallback((ref) => {
    if (itil === null && ref) {
        const itilObj = intlTelInput(ref, {
          initialCountry: "us",
          utilsScript: utilsScriptUrl,
          // Azerbaijan, Bangladesh, Oman, Pakistan, Sri Lanka, Tajikistan, Algeria
          excludeCountries: ["az", "bd", "om", "pk", "lk", "tj", "dz"],
        });
        if (phoneNumber !== "") {
          itilObj.setNumber(phoneNumber);
        }
        setItil(itilObj);
    }
  }, []);

  const getCookie = (key) => {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }

  const resetCookie = (key) => {
    document.cookie = key + "=; secure; domain=.joinpap.com; path=/;";
  }

  const handleVerificationMsgSend = async () => {
    if (!itil.isValidNumber()) {
      toast(`Please provide a valid phone number.`);
      return;
    }
    const currPhoneNumber = itil.getNumber();
    setPhoneNumber(currPhoneNumber);

    const user_info_handler = httpsCallable(functions, "user_info_handler");
    setSendButtonEnabled(false);
    setRemainingTime(30);
    const countdown = setInterval(() => {
      setRemainingTime(remainingTime => {
        if (remainingTime === 1) {
          clearInterval(countdown);
          setSendButtonEnabled(true);
        }
        return remainingTime - 1;
      });
    }, 1000);
    user_info_handler({
      "phone_number": currPhoneNumber,
      "token": token,
      "referral_code": referralCode,
    }).then((resp) => {
      if (resp.data.status === "invalid_input") {
        setRemainingTime(1);
        clearInterval(countdown);
        toast(`Please provide a valid phone number. (90001)`);
        setSendButtonEnabled(true);
        return
      }
      if (resp.data.status === "not_found") {
        setRemainingTime(3);
        toast(`Invalid session. Redirect after 3s (90002)`);
        setInterval(() => {
          resetCookie("_token");
          window.location.href = "https://www.joinpap.com/";
        }, 3000);
        return
      }
      if (resp.data.status === "error") {
        setRemainingTime(1);
        clearInterval(countdown);
        toast(`Unknown error, please try again later. (90009)`);
        setSendButtonEnabled(true);
        return;
      }
      setShowVerification(true);
      setRemainingTime(10);
    }).catch((e) => {
        console.log("error", e);
    });
  };

  const handleKeyDown = async (event) => {
    if (event.keyCode === 13 || event.key === "Enter") {
      handleCodeSubmit();
    }
  }

  const handleCodeSubmit = async () => {
    if (!/^[0-9]{6}$/.test(inputCode)) {
      toast(`Please provide a valid code.`);
      return;
    }

    setSubmitCodeButtonEnabled(false);

    const user_info_handler = httpsCallable(functions, "user_info_handler");
    user_info_handler({
        "phone_number": phoneNumber,
        "token": token,
        "code": inputCode,
        "referral_code": referralCode,
    }).then((resp) => {
      if (resp.data.status === "success") {
        resetCookie("_token");
        window.location.href = "https://www.joinpap.com/welcome";
        return;
      }

      if (resp.data.status === "error") {
        toast(`Please provide a valid code.`);
      } else if (resp.data.status === "timeout") {
        toast(`Code has expired, please send another token.`);
      }
      setSubmitCodeButtonEnabled(true);
    }).catch((e) => {
        console.log("error", e);
    });
  };

  const handleBack = async () => {
    setRemainingTime(1);
    setShowVerification(false);
  };

  return (
    <>
      <div id="loader"></div>
      <main>
        <div className="relative">
          <Container className="pap-yellow h-screen flex items-center justify-center">
          {!showVerification? (
            <div className="w-11/12 bg-white p-10 rounded-3xl shadow-xl sm:w-3/4 md:w-1/2 lg:w-2/5 2xl:w-1/4">
              <p className="leading-5 font-medium mb-4">One more thing!</p>
              <p className="leading-5 font-medium mb-4">Enter your phone number to receive a one time passcode.</p>
              <div className="mb-4">
                <label className="text-lg font-semibold mb-4" htmlFor="phone">Phone Number</label>
                <div>
                  <input type="phone" className="w-full p-2 border border-gray-300 rounded-md" ref={phoneInputRef}/>
                </div>
              </div>
              <button type="button"
                className={`w-2/3 text-yellow-900 text-lg font-semibold py-2 rounded-xl sm:w-1/2 md:w-2/5 ${sendButtonEnabled ? 'bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-offset-2' : 'bg-yellow-700 disabled:pointer-events-none'}`}
                onClick={() => {handleVerificationMsgSend();}}
                disabled={!sendButtonEnabled}
              >
                {sendButtonEnabled ? "Submit" : `Wait ${remainingTime}s`}
              </button>
            </div>
            ) : (
            <div className="w-11/12 bg-white p-10 rounded-3xl shadow-xl sm:w-3/4 md:w-1/2 lg:w-2/5 2xl:w-1/4">
              <p className="leading-5 font-medium mb-4">Check your text messages!</p>
              <p className="leading-5 font-medium mb-4">Please enter the 6-digit code.</p>
              <input
                type="tel"
                name="text-name"
                id="text-id"
                pattern="[0-9]{6}"
                className="w-full block mr-6 inline-flex rounded-md border-gray-300 shadow-sm mt-1 mb-4 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onChange={(e) => setInputCode(e.target.value)}
                onKeyDown={(e) => {handleKeyDown(e);}}
                required
                placeholder="123456"
              />
              <button type="button"
                className={`w-5/12 text-yellow-900 text-lg font-semibold py-2 mr-4 mb-2 rounded-xl ${submitCodeButtonEnabled ? 'bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-offset-2' : 'bg-yellow-700 disabled:pointer-events-none'}`}
                onClick={() => {handleCodeSubmit();}}
                disabled={!submitCodeButtonEnabled}
              >Verify</button>
              <button type="button"
                className="w-5/12 bg-gray-100 text-yellow-500 text-lg font-semibold py-2 rounded-xl hover:bg-gray-200"
                onClick={() => {handleBack();}}
              >
                Back
              </button>
            </div>
            )}
          </Container>
        </div>
        <Footer companyName="pap!" />
      </main>
    </>
  );
}

import toast from 'react-hot-toast';
import { auth } from 'services/firebase';
import { useNavigate, useLocation } from 'react-router';
import React, { useState } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  MultiFactorSession,
  MultiFactorInfo,
  PhoneInfoOptions,
} from 'firebase/auth';


export function OneClickSignup() {
  const navigate = useNavigate();
  const { hash } = useLocation();

  const [verificationId, setVerificationId] = useState(null);

  return (
  <>
    <main>
      <div className="flex min-h-full flex-col justify-center py-12 px-5 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full md:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">{"Welcome to pap!"}</h2>
          <h4 className="mt-3 text-sm text-gray-700">You will be redirected to Google Sign In page to authorize this app to track your receipts :)</h4>
          <h4 className="mt-3 text-sm text-gray-700">Note: As Kamal mentioned on the phone, Google will be "warning" you that this app is not "safe". But since you trust me, just click on "Advanced"</h4>
          <h4 className="mt-3 text-sm text-gray-700">Then, since you trust me again, please click on the link below</h4>
          <h4 className="mt-3 text-sm text-gray-700"><b>We Love You</b></h4>
          <h4 className="mt-3 text-sm text-gray-700">pap! Team — Kamal, Carlo, Anthony, Francis, David :)</h4>
          <h4 className="mt-3 text-sm text-gray-700">We will <b>only</b> be processing receipts, and nothing else!</h4>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full md:max-w-md rounded-lg border border-gray-200 shadow-lg">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6"
              onSubmit={(event) => {
                event.preventDefault();
                const state = (Math.random() + 1).toString(36).substring(2);
//                 window.location.href = "https://accounts.google.com/o/oauth2/auth?client_id=69825809285-j0hs7rhus3oougluep0ns6jcqub8nkq8.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fus-central1-price-adjustment-refund.cloudfunctions.net%2Fgoogle_oauth_callback&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&access_type=offline&response_type=code&approval_prompt=force&state=" + state;
//                 window.location.href = "https://accounts.google.com/o/oauth2/auth?client_id=69825809285-j0hs7rhus3oougluep0ns6jcqub8nkq8.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fportal.joinpap.com%2Fjoin&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&access_type=offline&response_type=code&approval_prompt=force&state=" + state;
//                 window.location.href = "https://accounts.google.com/o/oauth2/auth?client_id=69825809285-j0hs7rhus3oougluep0ns6jcqub8nkq8.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fportal.joinpap.com%2Fjoin&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuser.addresses.read+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuser.birthday.read+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuser.gender.read&access_type=offline&response_type=code&approval_prompt=force&state=" + state;
                window.location.href = "https://accounts.google.com/o/oauth2/auth?client_id=69825809285-j0hs7rhus3oougluep0ns6jcqub8nkq8.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fportal.joinpap.com%2Fgoogle_oauth_callback_v2&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&access_type=offline&response_type=code&approval_prompt=force&state=" + state;
              }}
            >
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Google Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full md:max-w-md rounded-lg border border-gray-200 shadow-lg">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6"
              onSubmit={(event) => {
                event.preventDefault();
                window.location.href = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=436b867e-58fe-4cf1-96a7-1a90f36c000c&redirect_uri=https%3A%2F%2Fportal.joinpap.com%2Foutlook_oauth_callback_v2&scope=openid+profile+offline_access+https%3A%2F%2Fgraph.microsoft.com%2FMail.Read+https%3A%2F%2Fgraph.microsoft.com%2FUser.Read&access_type=offline&response_type=code&approval_prompt=force";
              }}
            >
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Outlook Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="sm:mx-auto sm:w-full md:max-w-md">
          <h4 className="mt-6 text-sm text-gray-700">Reminder: You’re part of the first 100 users that will <b>help us</b> create the next vision in online shopping!”</h4>
          <h4 className="mt-3 text-sm text-gray-700">Sorry for those that do not use Gmail and Outlook :( We’re working on including all other email providers soon!</h4>
          <h4 className="mt-3 text-sm text-gray-700">If you have questions / concerns, please call Kamal! (+1 510 542 0847)</h4>
        </div>
      </div>
    </main>
  </>
  )
}

import { Container } from "components/Container";
import { Header } from "components/Header";
import { getEmailById, getUserById } from "services/firebase";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "services/firebase";
import { formatDate, daysAgo } from "utils/time";


import toast from "react-hot-toast";
import { AdminLightSidebar } from "components/AdminLightSidebar";

export function AdminEmailViewer() {
  let { mode, email_id, type } = useParams();
  const [htmlContent, setHtmlContent] = useState("");
  const [emailContent, setEmailContent] = useState([]);
  const [attachmentContent, setAttachmentContent] = useState("");
  const [attachmentName, setAttachmentName] = useState("");
  useEffect(() => onAuthStateChanged(auth, (user) => {
    if (!user) {
      toast.error(`Please login first to access data.`);
      // redirect
      window.location = "/sign-in";
      return;
    }
    getUserById(user.uid).then((user) => {
      if (!user.is_admin) {
        toast.error(`You are not authorized to access this page.`);
        window.location = "/";
        return;
      }
    });

    var emailPromise = getEmailById(email_id, mode);
    emailPromise.then((email) => {
      if (email === null) {
        toast.error(`Email not found.`);
        return;
      }
      setEmailContent(email.body);
      if (email.html !== undefined && email.html.length > 0) {
        var htmlStr = email.html[0]
        if (email.source === "gmail") {
          htmlStr = htmlStr.substr(2, email.html[0].length - 3)
        }
        htmlStr = htmlStr.replace(/\\t/g, '')
          .replace(/\\n\\r/g, '')
          .replace(/\\r/g, '')
          .replace(/\\n/g, '')
//           .replace(/\\x[a-fA-F0-9]{2}/g, '')
        htmlStr = fullyDecode(htmlStr);
        setHtmlContent(htmlStr);
      }
      if (email.attachments !== undefined && email.attachments.length > 0 && email.attachments[0].extracted_text !== undefined) {
        setAttachmentName(email.attachments[0].filename)
        setAttachmentContent("<p>" + email.attachments[0].extracted_text.replace(/\n/g, "<br />") + "</p>");
      }
    });
  }), []);

  const decodeEscapedUnicode = (htmlStr) => {
    return htmlStr.replace(/\\x([0-9A-Fa-f]{2})/g, (_, hex) => {
      return String.fromCharCode(parseInt(hex, 16));
    }).replace(/\\u([0-9A-Fa-f]{4})/g, (_, hex) => {
      return String.fromCharCode(parseInt(hex, 16));
    });
  }

  const decodeHtmlEntities = (htmlStr) => {
    var txtArea = document.createElement("textarea");
    txtArea.innerHTML = htmlStr;
    return txtArea.value;
  }

  const fullyDecode = (htmlStr) => {
    htmlStr = unescape(htmlStr.replace(/\\x/g, '%'));
    let decoder = new TextDecoder('utf-8');
    let bytes = new Uint8Array(htmlStr.split('').map(char => char.charCodeAt(0)));
    return decoder.decode(bytes);
  }

  return (
    <>
      <main>
        <div className="relative">
          <div className="border-b border-gray-200 border-none">
          {type !== "text" && htmlContent !== ""?
            (<div dangerouslySetInnerHTML={{__html: htmlContent}} />) :
            (<p className="mt-1 max-w-4xl text-lg text-gray-800 font-poppins">{emailContent}</p>)
            }
          </div>
          {attachmentContent !== "" && (
          <div className="border-b border-gray-200 border-none">
            <h3 className="text-2xl text-gray-900">-----{attachmentName}-----</h3>
            <div dangerouslySetInnerHTML={{__html: attachmentContent}} />
            <h3 className="text-2xl text-gray-900">--------------------------</h3>
          </div>)}
        </div>
      </main>
    </>
  );
}

import { Footer } from "components/Footer";
import { Container } from "components/Container";
import { Table } from "components/Table";
import { Header } from "components/Header";
import { getProductsByStatus, getUserById, editRefundAndPapProductStatus } from "services/firebase";
import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "services/firebase";
import { parseSlashedDate, parseDashDate, formatDate, daysAgo, epochSecToDate } from "utils/time";


import toast from "react-hot-toast";
import { AdminLightSidebar } from "components/AdminLightSidebar";

export function AdminRefunds() {
  const [seed, setSeed] = useState(1);
  const [refunds, setRefunds] = useState([]);

  useEffect(() => onAuthStateChanged(auth, (user) => {
    if (!user) {
      toast.error(`Please login first to access data.`);
      // redirect
      window.location = "/sign-in";
      return;
    }
    getUserById(user.uid).then((user) => {
      if (!user.is_admin) {
        toast.error(`You are not authorized to access this page.`);
        window.location = "/";
        return;
      }
    });
    getProductsByStatus(["refund-available", "refund-pending", "refund-complete"]).then((fetchedProducts) => {
      if (!fetchedProducts) {
        toast(`No refunds found`);
        return;
      }

      fetchedProducts.map((product) => {
        if (product.status === "refund-available") {
          product.status = "Available";
        } else if (product.status === "refund-pending") {
          product.status = "Pending";
        } else if (product.status === "refund-complete") {
          product.status = "Completed";
        }

        product.spec = "";
        if (product.color !== undefined && product.color !== "" && product.color !== "NA") {
          product.spec += " " + product.color;
        }
        if (product.size !== undefined && product.size !== "" && product.size !== "NA") {
          product.spec += " " + product.size;
        }
        if (product.dimensions !== undefined && product.dimensions !== "" && product.dimensions !== "NA") {
          product.spec += " " + product.dimensions;
        }
        product.spec = product.spec.trim();

        if (product.product_link) {
          product.link = "link";
        }

        if (product.purchase_time === undefined || product.purchase_time === "NA") {
          product.purchase_time = "";
        } else if (product.purchase_time.includes('/')) {
          var dateObj = parseSlashedDate(product.purchase_time.replace(/[^0-9/-]/g, ''));
          product.receipt_time = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
        } else if (product.purchase_time.includes('-')) {
          var dateObj = parseDashDate(product.purchase_time.replace(/[^0-9/-]/g, ''));
          product.receipt_time = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
        }

        if (product.email_time_sec <= 0) {
          product.email_time = "";
        } else {
          var dateObj = epochSecToDate(product.email_time_sec);
          product.email_time = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
        }

        if (product.title_score !== undefined) {
          product.title_score = product.title_score.toFixed(2);
        }

        if (product.source === "gmail") {
          product.source = "Gmail";
        } else if (product.source === "manual") {
          product.source = "Manual";
        }
      });
      fetchedProducts = dedupProducts(fetchedProducts);
      setRefunds(fetchedProducts);
    });
  }), []);

  const dedupProducts = (products) => {
    const seenIds = new Set();
    const res = [];

    products.forEach(product => {
        const dedupKey = product.pap_product_id + "|" + product.email;
        var dedupKey2 = "";
        if (product.item_number != undefined && product.item_number !== "" && (product.item_number + "").toLowerCase() !== "na") {
          dedupKey2 = product.seller_name + "|" + product.email + "|" + product.item_number;
        }

        if (!seenIds.has(dedupKey) && (dedupKey2 === "" || !seenIds.has(dedupKey2))) {
            res.push(product);
        }

        seenIds.add(dedupKey);
        if (dedupKey2 !== "") {
          seenIds.add(dedupKey2);
        }
    });

    return res;
  }

  const reset = () => {
    setSeed(Math.random());
  }

  return (
    <>
      <div id="loader"></div>
      <main>
        <AdminLightSidebar>
          <div className="relative pb-20 sm:py-5">
            <Container className="relative lg:pt-8">
              {" "}
              <Header title={"All Refunds"} content={"Lists all refunds. Remember to view products in cognito mode."} />{" "}
            </Container>
            <Container className="relative lg:pt-8" key={seed}>
              {" "}
              <Table
                idField="id"
                rows={refunds}
                columns={[
                  {
                    attribute: "email",
                    attributeName: "email",
                    width: 10,
                  },
                  {
                    attribute: "product_name",
                    attributeName: "receipt title",
                    width: 50,
                  },
                  {
                    attribute: "receipt_time",
                    attributeName: "receipt time",
                    width: 10,
                  },
                  {
                    attribute: "email_time",
                    attributeName: "email time",
                    width: 10,
                  },
                  {
                    attribute: "spec",
                    attributeName: "spec",
                    width: 20,
                  },
                  {
                    attribute: "crawled_product_title",
                    attributeName: "matched title",
                    width: 50,
                  },
                  {
                    attribute: "title_score",
                    attributeName: "title similarity",
                    width: 10,
                  },
                  {
                    attribute: "seller_name",
                    attributeName: "retailer",
                    width: 20,
                  },
                  {
                    attribute: "link",
                    attributeName: "link",
                    width: 20,
                    onClick: (row) => {window.open(row.product_link, '_blank').focus();},
                  },
                  {
                    attribute: "unit_price",
                    attributeName: "purchased price",
                    width: 5,
                  },
                  {
                    attribute: "last_checked_price",
                    attributeName: "current price",
                    width: 5,
                  },
                  {
                    attribute: "source",
                    attributeName: "source",
                    width: 5,
                  },
                  {
                    attribute: "status",
                    attributeName: "status",
                    width: 20,
                    type: "RefundStatusDropdown",
                    onClick: (row, val) => {
                      row.status = val;
                      if (val === "Available") {
                        val = "refund-available";
                      } else if (val === "Pending") {
                        val = "refund-pending";
                      } else if (val === "Completed") {
                        val = "refund-complete";
                      } else if (val === "Active") {
                        val = "active";
                      } else if (val === "Need Attention") {
                        val = "need-attention";
                      } else if (val === "Invalid") {
                        val = "invalid";
                      }
                      editRefundAndPapProductStatus(row.docRef, val);
                      reset();
                    },
                  },
                  {
                    attribute: "docRef",
                    attributeName: "id",
                    width: 5,
                    onClick: (row) => {window.open("/view-email/" + row.docRef.substring(0, row.docRef.lastIndexOf('_')), '_blank').focus();},
                  },
                ]}
              />{" "}
            </Container>
          </div>
          <Footer companyName="pap!" />
        </AdminLightSidebar>
      </main>
    </>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { XIcon, ShieldIcon, LockIcon, RefreshCwIcon } from "lucide-react";
import { httpsCallable } from "firebase/functions";
import { functions, savePhoneCode } from "services/firebase";

import "intl-tel-input/build/css/intlTelInput.min.css";
import intlTelInput from "https://cdn.skypack.dev/intl-tel-input@19.1.0";
import toast from "react-hot-toast";

const utilsScriptUrl = "https://cdn.jsdelivr.net/npm/intl-tel-input@19.1.0/build/js/utils.js";

export function PapSignupModal() {
  const [isOpen, setIsOpen] = useState(true);
  const [step, setStep] = useState(1);
  const [ip, setIp] = useState("");
  const [referrer, setReferrer] = useState("pap");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [token, setToken] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isOtpFocused, setIsOtpFocused] = useState(Array(6).fill(false));
  const [phoneNumberButtonEnabled, setPhoneNumberButtonEnabled] = useState(true);
  const [submitCodeButtonEnabled, setSubmitCodeButtonEnabled] = useState(true);
  const [remainingTime, setRemainingTime] = useState(0);

  const [itil, setItil] = useState(null);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    initIpAddress();
  }, []);

  const phoneInputRef = useCallback((ref) => {
    if (itil === null && ref) {
        const itilObj = intlTelInput(ref, {
          initialCountry: "us",
          utilsScript: utilsScriptUrl,
          // Azerbaijan, Bangladesh, Israel, Oman, Pakistan, Sri Lanka, Tajikistan, Algeria
          excludeCountries: ["az", "bd", "il", "om", "pk", "lk", "tj", "dz"],
          customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
            return "Phone number";
          },
        });
        if (phoneNumber !== "") {
          itilObj.setNumber(phoneNumber);
        }
        setItil(itilObj);
    }
  }, []);

  const handleCodeKeyDown = (key, value, index) => {
    if (key !== "Backspace" && key !== "Delete") {
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = "";
    setOtp(newOtp);

    if (index > 0) {
      const nextInput = document.getElementById(`otp-input-${index - 1}`);
      if (nextInput) {
          nextInput.focus();
      }
    }
  };

  const handleCodeChange = (value, index) => {
    if (/^[0-9]{6}$/.test(value) && index === 0) {
      setOtp(value.split(''));
      const currInput = document.getElementById(`otp-input-${index}`);
      if (currInput) {
        currInput.blur();
      }
      return;
    }
    if (!/^[0-9]{1,2}$/.test(value)) {
      return;
    }
    if (value.length == 2 && otp[index] !== "" && value.includes(otp[index])) {
      if (value[0] === otp[index] && index < 5) {
        value = value.slice(1);
      } else {
        value = value.replace(otp[index], "");
      }
    } else if (value.length == 2) {
      value = value.slice(1);
    }
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
          nextInput.focus();
      }
    } else if (index >= 5) {
      const nextInput = document.getElementById(`otp-input-${index}`);
      if (nextInput) {
          nextInput.blur();
      }
    }
  };

  const handleCodeTileFocus = (index) => {
    const newIsOtpFocused = [...isOtpFocused];
    newIsOtpFocused[index] = true;
    setIsOtpFocused(newIsOtpFocused);
  };

  const handleCodeTileBlur = (index) => {
    const newIsOtpFocused = [...isOtpFocused];
    newIsOtpFocused[index] = false;
    setIsOtpFocused(newIsOtpFocused);
  }

  const handleVerificationMsgSend = async () => {
    if (!itil.isValidNumber()) {
      toast(`Please provide a valid phone number.`);
      return;
    }
    const currPhoneNumber = itil.getNumber();
    setCookie("_phone", currPhoneNumber);
    setPhoneNumber(currPhoneNumber);

    const user_info_handler = httpsCallable(functions, "user_info_handler_v2");
    setPhoneNumberButtonEnabled(false);
    setRemainingTime(30);
    const countdown = setInterval(() => {
      setRemainingTime(remainingTime => {
        if (remainingTime === 1) {
          clearInterval(countdown);
          setPhoneNumberButtonEnabled(true);
        }
        return remainingTime - 1;
      });
    }, 1000);
    user_info_handler({
      "phone_number": currPhoneNumber,
    }).then((resp) => {
      if (resp.data.status === "invalid_input") {
        setRemainingTime(1);
        clearInterval(countdown);
        toast(`Please provide a valid phone number. (90001)`);
        setPhoneNumberButtonEnabled(true);
        return
      }
      if (resp.data.status === "not_found") {
        setRemainingTime(3);
        toast(`Invalid session. Redirect after 3s (90002)`);
        setInterval(() => {
          resetCookie("_token");
          window.location.href = "https://www.joinpap.com/";
        }, 3000);
        return
      }
      if (resp.data.status === "error") {
        setRemainingTime(1);
        clearInterval(countdown);
        toast(`Unknown error, please try again later. (90009)`);
        setPhoneNumberButtonEnabled(true);
        return;
      }
      setToken(resp.data.token);
      setStep(3);
      setRemainingTime(10);
    }).catch((e) => {
        console.log("error", e);
    });
  };

  const handleCodeSubmit = async () => {
    const inputCode = otp.join("")
    if (!/^[0-9]{6}$/.test(inputCode)) {
      toast(`Please provide a valid code.`);
      return;
    }

    setSubmitCodeButtonEnabled(false);

    const user_info_handler = httpsCallable(functions, "user_info_handler_v2");
    user_info_handler({
        "phone_number": phoneNumber,
        "token": token,
        "code": inputCode,
    }).then((resp) => {
      if (resp.data.status === "success") {
        setStep(4);
        setSubmitCodeButtonEnabled(true);
        return;
      }

      if (resp.data.status === "error") {
        toast(`Please provide a valid code.`);
      } else if (resp.data.status === "timeout") {
        toast(`Code has expired, please send another token.`);
      }
      setSubmitCodeButtonEnabled(true);
    }).catch((e) => {
        console.log("error", e);
    });
  };

  const formatPhoneNumber = (phone) => {
    const length = phone.length;
    if (length > 4) {
      return `***-***-${phone.slice(length - 4)}`;
    }
    return phone;
  };

  const resetCookie = (key) => {
    document.cookie = key + "=; secure; domain=.joinpap.com; path=/;";
  }

  const setCookie = (key, val) => {
    document.cookie = key + "=" + val + "; secure; domain=.joinpap.com; path=/;";
  }

  const openNewWindow = (url) => {
    window.open(url, "", "width=800, height=600");
  }

  const initIpAddress = async () => {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    const ipStr = data.ip.replace(/\./g, '_');
    setIp(ipStr);
  }

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-md w-11/12">
        <div className="flex justify-between items-center p-6 border-b border-gray-200">
          <div className="flex items-center">
            <img
              src="https://cdn.prod.website-files.com/661aaea740edaca5a82103d3/662314e5432ba73de164b660_pap!.svg"
              alt="pap! Logo"
              className="h-6 mr-3"
            />
            <h2 className="text-xl font-semibold text-gray-800">
              {step === 1 ? "Connect to your Email Inbox" : step === 2 ? "Enter Your Phone Number" : step === 3 ? "Enter OTP" : "Sign Up"}
            </h2>
          </div>
          <button onClick={closeModal} className="text-gray-400 hover:text-gray-600">
            <XIcon size={24} />
          </button>
        </div>
        {step === 1 && (
          <div className="p-6">
            <div className="flex flex-col p-6">
              <p className="text-gray-600 mb-6">
                <a className="text-gray-800 text-lg font-semibold">pap! </a>
                partnered with
                <a href="https://developers.google.com/identity/protocols/oauth2/production-readiness/restricted-scope-verification#security-assessment" className="text-red-600 font-semibold"> Gmail </a>
                and
                <a href="https://www.joinpap.com/privacy-policy" className="text-blue-600 font-semibold"> Outlook </a>
                to offer you benefits. Here's what you need to know
              </p>
              <div className="my-6 space-y-4">
                <div className="flex items-center">
                  <ShieldIcon className="text-black-400 mr-3" size={24} />
                  <p className="text-gray-700">Your personal information is safe</p>
                </div>
                <div className="flex items-center">
                  <LockIcon className="text-black-400 mr-3" size={24} />
                  <p className="text-gray-700">We never store your login credentials</p>
                </div>
                <div className="flex items-center">
                  <RefreshCwIcon className="text-black-400 mr-3" size={24} />
                  <p className="text-gray-700">You can disconnect at any time</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center p-2 border-t border-gray-200 sm:p-6">
              <p className="text-xs text-gray-500 mb-4">
                By continuing, you agree to pap's <a href="https://www.joinpap.com/privacy-policy" className="text-blue-500">privacy policy</a> and <a href="https://www.joinpap.com/terms-and-conditions" className="text-blue-500">terms of service</a>.
              </p>
              <button
                onClick={() => setStep(2)}
                className="w-full px-6 py-3 bg-gray-800 text-white text-xl font-medium rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-150 ease-in-out shadow-md"
              >
                Continue
              </button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="p-6">
            <div className="flex flex-col p-6">
              <p className="text-gray-600 mb-6">
                Please enter your phone number to continue.
              </p>
              <div>
                <input type="phone" className="w-full px-3 py-2 border border-gray-300 rounded-md" ref={phoneInputRef}/>
              </div>
            </div>
            <div className="flex flex-col items-center p-2 pt-2 sm:p-6">
              <button
                onClick={() => handleVerificationMsgSend()}
                className="w-full px-6 py-3 bg-gray-800 text-white text-xl font-medium rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-150 ease-in-out shadow-md"
              >
                Submit
              </button>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="p-6">
            <div className="flex flex-col p-6">
              <p className="text-gray-600 mb-6">
                Please enter the verification code sent to {formatPhoneNumber(phoneNumber)}
              </p>
              <div className="flex justify-center space-x-1 mb-6 sm:space-x-2">
                {otp.map((data, index) => {
                  return (
                    <input
                      key={index}
                      id={`otp-input-${index}`}
                      type="tel"
                      maxLength={index === 0 ? "6" : "2"}
                      className="w-8 h-10 p-2 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent sm:w-10 sm:h-12"
                      value={data}
                      onFocus={() => handleCodeTileFocus(index)}
                      onBlur={() => handleCodeTileBlur(index)}
                      onChange={(e) => handleCodeChange(e.target.value, index)}
                      onKeyDown={(e) => handleCodeKeyDown(e.key, e.target.value, index)}
                    />
                  );
                })}
              </div>
              <button
                onClick={() => handleCodeSubmit()}
                className="w-full px-6 py-3 bg-gray-800 text-white text-xl font-medium rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-150 ease-in-out shadow-md"
              >
                Verify
              </button>
            </div>
          </div>
        )}
        {step === 4 && (
          <div className="p-6">
            <div className="flex flex-col p-2 pt-2 sm:p-6">
              <p className="text-gray-600 mb-6">
                Sign up with one of the following options:
              </p>
              <div className="space-y-4">
                <button id="google-oath"
                  onClick={() => openNewWindow("https://accounts.google.com/o/oauth2/auth?client_id=69825809285-j0hs7rhus3oougluep0ns6jcqub8nkq8.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fportal.joinpap.com%2Fgoogle_oauth_callback_v2&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&access_type=offline&response_type=code&approval_prompt=force&state=" + referrer + "-" + ip + "-" + token)}
                  className="w-full flex items-center justify-center px-4 py-3 border border-gray-300 rounded-xl shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  <img className="w-6 h-6 mr-3" src="https://cdn.prod.website-files.com/661aaea740edaca5a82103d3/663d7e3263a5497c3cf777a7_Google%20Icon.svg" loading="lazy" alt="Google Icon" />
                  Continue with Google
                  <i className="w-6 h-6 ml-3"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fillRule="evenodd" clipRule="evenodd" d="M6.25 6.25h4.286v-1.5H5.964c-.67 0-1.214.544-1.214 1.214v12.072c0 .67.544 1.214 1.214 1.214h12.072c.67 0 1.214-.544 1.214-1.214v-4.572h-1.5v4.286H6.25V6.25zm10.44 0H13.5v-1.5h5.75v5.75h-1.5V7.31l-7.72 7.72-1.06-1.06 7.72-7.72z" fill="currentColor"></path></svg></i>
                </button>
                <button id="outlook-oath"
                  onClick={() => openNewWindow("https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=436b867e-58fe-4cf1-96a7-1a90f36c000c&redirect_uri=https%3A%2F%2Fportal.joinpap.com%2Foutlook_oauth_callback_v2&scope=openid+profile+offline_access+https%3A%2F%2Fgraph.microsoft.com%2FMail.Read+https%3A%2F%2Fgraph.microsoft.com%2FUser.Read&access_type=offline&response_type=code&approval_prompt=force&state=" + referrer + "-" + ip + "-" + token)}
                  className="w-full flex items-center justify-center px-4 py-3 border border-gray-300 rounded-xl shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  <img className="w-6 h-6 mr-3" src="https://cdn.prod.website-files.com/661aaea740edaca5a82103d3/663d7e89de9a4241f85401cc_Outlook%20Icon.svg" loading="lazy" alt="Outlook Icon" />
                  Continue with Outlook
                  <i className="w-6 h-6 ml-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fillRule="evenodd" clipRule="evenodd" d="M6.25 6.25h4.286v-1.5H5.964c-.67 0-1.214.544-1.214 1.214v12.072c0 .67.544 1.214 1.214 1.214h12.072c.67 0 1.214-.544 1.214-1.214v-4.572h-1.5v4.286H6.25V6.25zm10.44 0H13.5v-1.5h5.75v5.75h-1.5V7.31l-7.72 7.72-1.06-1.06 7.72-7.72z" fill="currentColor"></path></svg></i>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

import { Footer } from "components/Footer";
import { Container } from "components/Container";
import { Table } from "components/Table";
import { Header } from "components/Header";
import { StatsWithSharedBorders } from "components/StatsWithSharedBorders";
import { getRefundsWithPagination, getUserById, editRefundAndPapProductStatus, getRefundCountByStatus } from "services/firebase";
import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "services/firebase";
import { parseSlashedDate, parseDashDate, formatDate, daysAgo, epochSecToDate } from "utils/time";


import toast from "react-hot-toast";
import { AdminLightSidebar } from "components/AdminLightSidebar";

export function AdminProducts() {
  const [seed, setSeed] = useState(1);
  const [products, setProducts] = useState([]);
  const [userFilter, setUserFilter] = useState("");
  const [searchEmailInput, setSearchEmailInput] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [devMode, setDevMode] = useState("");
  const [lastProduct, setLastProduct] = useState(null);
  const [activeCnt, setActiveCnt] = useState(0);
  const [refundCnt, setRefundCnt] = useState(0);
  const [mismatchCnt, setMismatchCnt] = useState(0);
  const [unknowSellerCnt, setUnknowSellerCnt] = useState(0);
  const [invalidCnt, setInvalidCnt] = useState(0);
  const [noMatchingCnt, setNoMatchingCnt] = useState(0);
  const initPageSize = 20;
  const pageSize = 50;

  useEffect(() => onAuthStateChanged(auth, (user) => {
    if (!user) {
      toast.error(`Please login first to access data.`);
      // redirect
      window.location = "/sign-in";
      return;
    }
    getUserById(user.uid).then((user) => {
      if (!user.is_admin) {
        toast.error(`You are not authorized to access this page.`);
        window.location = "/";
        return;
      }
    });
    getRefundCountByStatus().then((productCnts) => {
      setActiveCnt(productCnts.active);
      setRefundCnt(productCnts.refund);
      setMismatchCnt(productCnts.mismatch);
      setUnknowSellerCnt(productCnts.unknowseller);
      setNoMatchingCnt(productCnts.nomatching);
      setInvalidCnt(productCnts.invalid);
    });
    getRefundsWithPagination("", "", "", initPageSize, null).then((fetchedProducts) => {
      setProducts(processProducts(fetchedProducts));
    });
  }), []);

  const processProducts = (fetchedProducts) => {
    if (!fetchedProducts || fetchedProducts.length === 0) {
      toast(`No more products.`);
      return [];
    }

    setLastProduct(fetchedProducts[fetchedProducts.length-1]);
    fetchedProducts = fetchedProducts.map(doc =>({docRef: doc.ref.id, ...doc.data()}));
    fetchedProducts = fetchedProducts.filter(product => {
      return !product.is_duplicated;
    })

    fetchedProducts.map((product) => {
      if (product.status === "refund-available") {
        product.status = "Available";
        product.backgroundColor = "#0080003d";
      } else if (product.status === "refund-pending") {
        product.status = "Pending";
        product.backgroundColor = "#0080003d";
      } else if (product.status === "refund-complete") {
        product.status = "Completed";
        product.backgroundColor = "#0080003d";
      } else if (product.status === "active") {
        product.status = "Active";
      } else if (product.status === "invalid") {
        product.status = "Invalid";
      } else if (product.status === "inactive") {
        product.status = "Inactive";
      } else if (product.status === "abnormal-price") {
        product.status = "Abnormal Price";
      } else if (product.status === "low-title-score") {
        product.status = "Low Title Similarity";
      } else if (product.status === "unknown-seller") {
        product.status = "Unknown Seller";
      } else if (product.status === "spec-mismatch") {
        product.status = "Spec Mismatch";
      } else if (product.status === "expired") {
        product.status = "Expired";
      } else if (product.status === "no-matching") {
        product.status = "No Matching";
      } else if (product.status === "no-longer-available") {
        product.status = "No Longer Available";
      } else if (product.status === "need-attention") {
        product.status = "Need Attention";
      } else if (product.status === "unsupported-category") {
        product.status = "Unsupported Category";
      } else if (product.status === "unsupported-currency") {
        product.status = "Other Currency " + product.currency;
      }

      product.spec = "";
      if (product.color !== undefined && product.color !== "" && product.color !== "NA") {
        product.spec += " " + product.color;
      }
      if (product.size !== undefined && product.size !== "" && product.size !== "NA") {
        product.spec += " " + product.size;
      }
      if (product.dimensions !== undefined && product.dimensions !== "" && product.dimensions !== "NA") {
        product.spec += " " + product.dimensions;
      }
      product.spec = product.spec.trim();

      if (product.product_link) {
        product.link = "link";
      }

      product.display_name = product.product_name
      if (product.display_name !== undefined && product.display_name.length > 60) {
          product.display_name = product.display_name.substring(0, 60) + "...";
      }

      if (product.purchase_time === undefined || product.purchase_time === "NA") {
        product.purchase_time = "";
      } else if (product.purchase_time.includes('/')) {
        var dateObj = parseSlashedDate(product.purchase_time.replace(/[^0-9/-]/g, ''));
        product.receipt_time = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
      } else if (product.purchase_time.includes('-')) {
        var dateObj = parseDashDate(product.purchase_time.replace(/[^0-9/-]/g, ''));
        product.receipt_time = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
      }

      if (product.email_time_sec <= 0) {
        product.email_time = "";
      } else {
        var dateObj = epochSecToDate(product.email_time_sec);
        product.email_time = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
      }

      if (product.last_checked_timestamp <= 0) {
        product.last_checked_time = "";
      } else {
        var dateObj = epochSecToDate(product.last_checked_timestamp);
        product.last_checked_time = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
      }

      if (product.title_score !== undefined) {
        product.title_score = product.title_score.toFixed(2);
      }

      if (product.source === "gmail") {
        product.source = "Gmail";
      } else if (product.source === "manual") {
        product.source = "Manual";
      }
    });

    return fetchedProducts;
  };

  const handleFilterChange = (type, val) => {
    getRefundsWithPagination(devMode, statusFilter, userFilter, initPageSize, null).then((fetchedProducts) => {
      setProducts(processProducts(fetchedProducts));
    });
  };

  const loadMore = () => {
    getRefundsWithPagination(devMode, statusFilter, userFilter, pageSize, lastProduct).then((fetchedProducts) => {
      const loadedProducts = processProducts(fetchedProducts);
      setProducts(products.concat(loadedProducts));
    });
  };

  const reset = () => {
    setSeed(Math.random());
  }

  return (
    <>
      <div id="loader"></div>
      <main>
        <AdminLightSidebar>
          <div className="relative pb-20 sm:py-5">
            <Container className="relative lg:pt-8">
              {" "}
              <Header title={"All Parsed Products"} content={"Lists all product matching. Remember to view products in cognito mode. "
              + "Expired, non-USD, invalid, and seller-unknown products are not included by default. You can see them by choosing "
              + "\"Invalid Only\" in dev mode. Counters: Active Products, Refunds (refund available, refund pending, refund complete), "
              + "Mismatched Products (low title score, spec mismatch), No Matching Products, Unknown Seller, Invalid "
              + "(invalid, unsupported currency, no longer available)"} />{" "}
            </Container>
            <Container className="relative lg:pt-2" key={seed}>
              <StatsWithSharedBorders
                stats={[
                  {
                    name: "Active Products",
                    stat: activeCnt,
                  },
                  {
                    name: "Refunds",
                    stat: refundCnt,
                  },
                  {
                    name: "Mismatched Products",
                    stat: mismatchCnt,
                  },
                  {
                    name: "No Matching Products",
                    stat: noMatchingCnt,
                  },
                  {
                    name: "Unknown Seller",
                    stat: unknowSellerCnt,
                  },
                  {
                    name: "Invalid",
                    stat: invalidCnt,
                  }
                ]}
              />
            </Container>
            <div className="FormField pt-10">
                <label className="ml-8 pl-5" htmlFor="userFilter">User:</label>
                <input
                  type="email" placeholder="Email" value={userFilter} onChange={(e) => setUserFilter(e.target.value)}
                  className="ml-3 mt-4 w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />

                <label className="pl-10 pr-3" htmlFor="statusFilter">Status:</label>
                <select className="rounded" id="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                    <option value="">All</option>
                    <option value="active">Active</option>
                    <option value="refund-available">Refund Available</option>
                    <option value="refund-pending">Refund Pending</option>
                    <option value="refund-complete">Refund Completed</option>
                    <option value="low-title-score">Low Title Similarity</option>
                    <option value="unknown-seller">Unknown Seller</option>
                    <option value="unsupported-currency">Unsupported Currency</option>
                    <option value="unsupported-category">Unsupported Category</option>
                    <option value="spec-mismatch">Spec Mismatch</option>
                    <option value="no-matching">No Matching</option>
                    <option value="no-longer-available">No Longer Available</option>
                    <option value="abnormal-price">Abnormal Price</option>
                    <option value="expired">Expired</option>
                    <option value="invalid">Invalid</option>
                </select>

                <label className="pl-10 pr-3" htmlFor="statusFilter">Dev Mode:</label>
                <select className="rounded" id="statusFilter" value={devMode} onChange={(e) => setDevMode(e.target.value)}>
                    <option value="">Off</option>
                    <option value="invalid-only">Invalid Only</option>
                    <option value="test-only">Test Only</option>
                    <option value="expired">Expired</option>
                </select>
                <button type="auth" class="mt-4 ml-3 inline-flex rounded-md border border-transparent bg-indigo-600 py-2 px-4
                  text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2
                  focus:ring-indigo-500 focus:ring-offset-2" onClick={(e) => handleFilterChange()}>Submit</button>
            </div>
            <Container className="relative lg:pt-8">
              {" "}
              <Table
                idField="id"
                rows={products}
                columns={[
                  {
                    attribute: "email",
                    attributeName: "email",
                    width: 10,
                  },
                  {
                    attribute: "receipt_time",
                    attributeName: "receipt time",
                    width: 10,
                  },
                  {
                    attribute: "email_time",
                    attributeName: "email time",
                    width: 10,
                  },
                  {
                    attribute: "display_name",
                    attributeName: "receipt title",
                    width: 50,
                  },
                  {
                    attribute: "spec",
                    attributeName: "spec",
                    width: 20,
                  },
                  {
                    attribute: "crawled_product_title",
                    attributeName: "matched title",
                    width: 50,
                  },
                  {
                    attribute: "title_score",
                    attributeName: "title similarity",
                    width: 10,
                  },
                  {
                    attribute: "seller_name",
                    attributeName: "retailer",
                    width: 20,
                  },
                  {
                    attribute: "link",
                    attributeName: "link",
                    width: 20,
                    onClick: (row) => {window.open(row.product_link, '_blank').focus();},
                  },
                  {
                    attribute: "unit_price",
                    attributeName: "purchased price",
                    width: 5,
                  },
                  {
                    attribute: "last_checked_price",
                    attributeName: "current price",
                    width: 5,
                  },
                  {
                    attribute: "source",
                    attributeName: "source",
                    width: 5,
                  },
                  {
                    attribute: "status",
                    attributeName: "status",
                    width: 20,
                    type: "ProductStatusDropdown",
                    onClick: (row, val) => {
                      row.status = val;
                      if (val === "Available") {
                        val = "refund-available";
                      } else if (val === "Pending") {
                        val = "refund-pending";
                      } else if (val === "Completed") {
                        val = "refund-complete";
                      } else if (val === "Active") {
                        val = "active";
                      } else if (val === "Need Attention") {
                        val = "need-attention";
                      } else if (val === "Invalid") {
                        val = "invalid";
                      } else if (val === "Spec Mismatch") {
                        val = "spec-mismatch";
                      } else if (val === "Low Title Similarity") {
                        val = "low-title-score";
                      } else if (val === "Unknown Seller") {
                        val = "unknown-seller";
                      } else if (val === "Abnormal Price") {
                        val = "abnormal-price";
                      } else if (val === "No Matching") {
                        val = "no-matching";
                      } else if (val === "Expired") {
                        val = "expired";
                      } else if (val === "Unsupported Category") {
                        val = "unsupported-category";
                      } else {
                        return;
                      }
                      editRefundAndPapProductStatus(row.docRef, val);
                      reset();
                    },
                  },
                  {
                    attribute: "last_checked_time",
                    attributeName: "last checked time",
                    width: 10,
                  },
                  {
                    attribute: "docRef",
                    attributeName: "id",
                    width: 5,
                    onClick: (row) => {window.open("/view-email/" + row.docRef.substring(0, row.docRef.lastIndexOf('_')), '_blank').focus();},
                  },
                ]}
              />{" "}
            </Container>
          </div>
          <button type="auth" class="ml-8 inline-flex rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={loadMore}>Load More</button>
          <Footer companyName="pap!" />
        </AdminLightSidebar>
      </main>
    </>
  );
}

import toast from 'react-hot-toast';
import { auth } from 'services/firebase';
import { useNavigate, useLocation } from 'react-router';
import React, { useState } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  MultiFactorSession,
  MultiFactorInfo,
  PhoneInfoOptions,
} from 'firebase/auth';


const getMfaResolver = (error: any) => {
  const multiFactorResolver = getMultiFactorResolver(auth, error)
  return multiFactorResolver;
}

const startMfaSignin = async (
  multiFactorHint: MultiFactorInfo,
  session: MultiFactorSession
) => {
  const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', { size: "invisible" });

  if (multiFactorHint.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
    const phoneInfoOptions: PhoneInfoOptions = {
      multiFactorHint: multiFactorHint,
      session: session
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    // Send SMS verification code
    const verificationId = await phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .catch(function (error) {
        toast.error(`Error verifying phone number. ${error}`);
        throw error;
      });
    return verificationId;
  } else {
    toast.error("Only Phone number 2FA are supported.");
  }
}

const finishMfaSignIn = async (verificationId, multiFactorResolver, verificationCode: String) => {
  // Get the SMS verification code sent to the user.
  if (verificationId && multiFactorResolver) {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    // Complete sign-in.
    await multiFactorResolver
      .resolveSignIn(multiFactorAssertion)
      .then(function (userCredential) {
        // User successfully signed in with the second factor phone number.
      })
      .catch(function (error: any) {
        throw error;
      });
  } else {
    throw new Error('Unknown error, please refresh and try again!');
  }
}
export class SignInAndRegisterProps {
  onSigninSuccess: Function;
  onRegisterSuccess: Function;
  blockSignup: Boolean;
  registerTitle: String;
  signInTitle: String;
}

export function SignInAndRegister(props: SignInAndRegisterProps) {
  const navigate = useNavigate();
  const { hash } = useLocation();

  const [verificationId, setVerificationId] = useState(null);
  const [mfaResolver, setMfaResolver] = useState(null);
  const [mfaPhoneNumber, setMfaPhoneNumber] = useState("");
  const [mfaCode, setMfaCode] = useState("");


  const isLogin = props.blockSignup || hash !== "#register";
  const isOneClickSignup = props.blockSignup || hash === "#one-click-register";
  return (
    <>
      <div className="px-6 pt-12 lg:px-8 lg:pt-20">
        <div className="text-center sm:align-center sm:flex sm:flex-col">
          <div className="relative mt-6 flex self-center rounded-lg bg-gray-100 p-0.5 sm:mt-8">
            <a
              type="button"
              href="#login"
              className={`${isLogin && !isOneClickSignup ? "bg-white" : ""} relative w-1/2 whitespace-nowrap rounded-md border-gray-200 py-2 text-sm font-medium text-gray-900 shadow-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:w-auto sm:px-8`}
            >
              Log in
            </a>
            <a
              type="button"
              href="#register"
              className={`${isLogin ? "" : "bg-white"} relative ml-0.5 w-1/2 whitespace-nowrap rounded-md border border-transparent py-2 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:w-auto sm:px-8`}
            >
              Sign Up
            </a>
            <a
              type="button"
              href="#one-click-register"
              className={`${isOneClickSignup ? "bg-white" : ""} relative ml-0.5 w-1/2 whitespace-nowrap rounded-md border border-transparent py-2 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:w-auto sm:px-8`}
            >
              One-Click Sign Up
            </a>
          </div>
        </div>
      </div>

      {/********************************* Login section **********************************/}
      <div className={`${isLogin && !isOneClickSignup ? "" : "hidden"} flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8`}>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">{props.signInTitle ? props.signInTitle : "Sign in to your account"}</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md rounded-lg border border-gray-200 shadow-lg">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6"
              onSubmit={(event) => {
                event.preventDefault();
                let email = '';
                let password = '';
                for (const target of event.target) {
                  if (target.name === 'email') {
                    email = target.value;
                  } else if (target.name === 'password') {
                    password = target.value;
                  }
                }
                signInWithEmailAndPassword(auth, email, password)
                  .then((userCredential) => {
                    if (props.onSigninSuccess) {
                      props.onSigninSuccess(userCredential);
                    } else {
                      toast.success("Login succeed!");
                    }
                  })
                  .catch((error) => {
                    if (error.code == 'auth/multi-factor-auth-required') {
                      toast.success("2FA verfication required");
                      // The user is a multi-factor user. 
                      // Second factor challenge is required.
                      const resolver = getMfaResolver(error);
                      setMfaResolver(resolver);
                      startMfaSignin(resolver.hints[0], resolver.session).then((verificationId) => {
                        setMfaPhoneNumber(resolver.hints[0].phoneNumber);
                        setVerificationId(verificationId);
                      });
                    } else if (error.message && error.message.includes("invalid-credential")) {
                      toast.error("Either email or password is invalid. Please try again or register.");
                    } else {
                      toast.error(error.message);
                    }
                  });
              }}
            >
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 text-left">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 text-left">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <button
                  id='recaptcha'
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>

          {
            mfaPhoneNumber && (
              <div className={`flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8`}>
                <form className='form-container' onSubmit={async (e) => {
                  e.preventDefault()

                  try {
                    await finishMfaSignIn(verificationId, mfaResolver, mfaCode);
                    setMfaCode('');
                    setMfaPhoneNumber('');
                    toast.success("Login succeed!");
                  } catch (error) {
                    toast.error(error);
                  }
                }}>
                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700 text-left">
                      Enter the 2FA code sent to {mfaPhoneNumber}
                    </label>
                    <div className="mt-1">
                      <input
                        type='text'
                        name="mfaCode"
                        value={mfaCode}
                        onChange={(e) => setMfaCode(e.target.value)}
                        placeholder="XXX XXX"
                        pattern="^[0-9]*$"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="flex mt-6 w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Submit Code
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )
          }
        </div>
      </div>

      {/********************************* Sign up section **********************************/}
      <div className={`${isLogin || isOneClickSignup ? "hidden" : ""} flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8`}>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">{props.registerTitle ? props.registerTitle : "Create your account"}</h2>
          <h4 className="mt-2 text-sm text-gray-700">You will be redirected to Google Sign In page to authorize this app to track your receipts :)</h4>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md rounded-lg border border-gray-200 shadow-lg">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6"
              onSubmit={(event) => {
                event.preventDefault();
                if (props.blockSignup) return;
                let email = '';
                let password = '';
                let reenteredPassword = '';
                for (const target of event.target) {
                  if (target.name === 'email') {
                    email = target.value;
                  } else if (target.name === 'password') {
                    password = target.value;
                  } else if (target.name === 'reenter_password') {
                    reenteredPassword = target.value;
                  }
                }
                if (password !== reenteredPassword) {
                  toast.error("Please make sure re-entered password matches the password you entered.");
                  return;
                }
                createUserWithEmailAndPassword(auth, email, password)
                  .then((userCredential) => {
                    toast.success("Sign up succeed!");
                    // TODO: send email verification with firebase sendEmailVerification
                    if (props.onRegisterSuccess) {
                      props.onRegisterSuccess(userCredential);
                    }
                  })
                  .catch((error) => {
                    if (error.message && error.message.includes("email-already-in-use")) {
                      toast.error("Email already in use. Please try to sign in.");
                    }
                  });
              }}
            >
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 text-left">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="register_email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 text-left">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="register_password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="reenter_password" className="block text-sm font-medium text-gray-700 text-left">
                  Re-enter Password
                </label>
                <div className="mt-1">
                  <input
                    id="reenter_password"
                    name="reenter_password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Create your account
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/********************************* Sign up section **********************************/}
      <div className={`${!isOneClickSignup ? "hidden" : ""} flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8`}>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">{"Welcome to pap!"}</h2>
          <h4 className="mt-3 text-sm text-gray-700">You will be redirected to Google Sign In page to authorize this app to track your receipts :)</h4>
          <h4 className="mt-3 text-sm text-gray-700">Note: As Kamal mentioned on the phone, Google will be "warning" you that this app is not "safe". But since you trust me, just click on "Advanced"</h4>
          <h4 className="mt-3 text-sm text-gray-700">Then, since you trust me again, please click on the link below</h4>
          <h4 className="mt-3 text-sm text-gray-700"><b>We Love You</b></h4>
          <h4 className="mt-3 text-sm text-gray-700">pap! Team — Kamal, Carlo, Anthony, Francis, David :)</h4>
          <h4 className="mt-3 text-sm text-gray-700">We will <b>only</b> be processing receipts, and nothing else!</h4>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md rounded-lg border border-gray-200 shadow-lg">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6"
              onSubmit={(event) => {
                event.preventDefault();
                if (props.blockSignup) return;
                const state = (Math.random() + 1).toString(36).substring(2);
                window.location.href = "https://accounts.google.com/o/oauth2/auth?client_id=69825809285-j0hs7rhus3oougluep0ns6jcqub8nkq8.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fportal.joinpap.com%2Fjoin&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&access_type=offline&response_type=code&approval_prompt=force&state=" + state;
              }}
            >
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Google OAuth
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h4 className="mt-6 text-sm text-gray-700">Reminder: You’re part of the first 100 users that will <b>help us</b> create the next vision in online shopping!”</h4>
          <h4 className="mt-3 text-sm text-gray-700">Sorry for those that do not use Gmail and Outlook :( We’re working on including all other email providers soon!</h4>
        </div>
      </div>
    </>
  )
}

import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react'

import { adminIdToName } from "utils/consts";


export class RefundTrackerModalProps {
  refundTrackerObj: Unknown;
  setRefundTrackerObj: Unknown;
  handleSubmit: Unknown;
  handleOperationLog: Unknown;
  show: Unknown;
  setShow: Unknown;
}


export function RefundTrackerModal(props: RefundTrackerModalProps) {
  const { refundTrackerObj, setRefundTrackerObj, handleSubmit, handleOperationLog, adminId, show, setShow } = props;

  const [resetable, setResetable] = useState(true);
  const [currAdminId, setCurrAdminId] = useState("");
  const [state, setState] = useState("");
  const [communicationMethod, setCommunicationMethod] = useState("");
  const [note, setNote] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  useEffect(() => {
    if (show && resetable) {
      if (refundTrackerObj == null) {
        setCurrAdminId("");
        setState("");
        setCommunicationMethod("");
        setNote("");
      } else {
        setCurrAdminId(refundTrackerObj.admin_id);
        setState(refundTrackerObj.state);
        setCommunicationMethod(refundTrackerObj.communication_method);
        setNote(refundTrackerObj.note);
      }
      setResetable(false);
    }
  });

  const handleRecordPhoneCall = () => {
    handleOperationLog(null, "phone_call", null, phoneNumber);
  };

  const handleRecordEmail = () => {
    handleOperationLog(null, "email", null, emailAddress);
  };

  const handleClaim = () => {
    setCurrAdminId(adminId);
  };

  const handleSubmitButtonClick = () => {
    const newTrackerObj = {
      admin_id: currAdminId,
      state: state,
      communication_method: communicationMethod,
      note: note,
    };
    setRefundTrackerObj(newTrackerObj);
    handleSubmit(newTrackerObj);
    setResetable(true);
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShow}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-6 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-lg sm:max-w-sm sm:p-6">
                <div className="w-full mt-3 md:max-w-lg sm:max-w-sm">
                  <label className="block whitespace-nowrap pr-3 text-md font-medium text-gray-900" htmlFor="text-input">Current Admin: {adminIdToName(currAdminId)}</label>
                  <button
                    type="button"
                    onClick={() => {handleClaim();}}
                    className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
                  >
                    Claim
                  </button>
                </div>
                <div className="w-full mt-3 md:max-w-lg sm:max-w-sm">
                  <label className="block w-full whitespace-nowrap pr-3 text-md font-medium text-gray-900" htmlFor="text-input">Refund State</label>
                  <select id="plan" value={state} onChange={(e) => setState(e.target.value)} className="w-1/2 block inline-flex rounded-md border-gray-300 shadow-sm mt-1 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    <option value=""></option>
                    <option value="new">New</option>
                    <option value="ongoing">On-going</option>
                    <option value="refunded">Refunded</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>
                <div className="w-full mt-3 md:max-w-lg sm:max-w-sm">
                  <label className="block w-full whitespace-nowrap pr-3 text-md font-medium text-gray-900" htmlFor="text-input">Communication Method</label>
                  <select id="plan" value={communicationMethod} onChange={(e) => setCommunicationMethod(e.target.value)} className="w-1/2 block inline-flex rounded-md border-gray-300 shadow-sm mt-1 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    <option value=""></option>
                    <option value="phone">Phone</option>
                    <option value="email">Email</option>
                    <option value="other">Other (specify in note)</option>
                  </select>
                </div>
                <div className="w-full mt-3 md:max-w-lg sm:max-w-sm">
                  <label className="whitespace-nowrap pr-3 text-md font-medium text-gray-900" htmlFor="text-input">Note</label>
                  <textarea
                    type="text"
                    name="text-name"
                    id="text-id"
                    className="block w-full rounded-md border-gray-300 shadow-sm mt-1 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    required
                    rows="10"
                    cols="60"
                  />
                </div>
                <div className="w-full mt-3 md:max-w-lg sm:max-w-sm">
                  <input
                    type="text"
                    name="text-name"
                    id="text-id"
                    className="w-1/2 block inline-flex rounded-md border-gray-300 shadow-sm mt-1 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                    placeholder="1(888)5556789"
                  />
                  <button
                    type="button"
                    onClick={() => {handleRecordPhoneCall();}}
                    className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
                  >
                    Record Phone Call
                  </button>
                </div>
                <div className="w-full mt-3 md:max-w-lg sm:max-w-sm">
                  <input
                    type="text"
                    name="text-name"
                    id="text-id"
                    className="w-1/2 block inline-flex rounded-md border-gray-300 shadow-sm mt-1 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    required
                    placeholder="1(888)5556789"
                  />
                  <button
                    type="button"
                    onClick={() => {handleRecordEmail();}}
                    className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
                  >
                    Record Email
                  </button>
                </div>
                <button
                  type="button"
                  onClick={() => {handleSubmitButtonClick();}}
                  className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
                >
                  Save Change
                </button>
                <button
                  type="button"
                  onClick={() => {setShow(false); setResetable(true);}}
                  className="mt-3 ml-4 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-400 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

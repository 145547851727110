import { Container } from 'components/Container'


export function Footer({ companyName }) {
  return (
    <footer className="py-5">
      <Container className="flex flex-col items-center justify-between md:flex-row">
        <div className="h-12 w-auto text-slate-900" tag="Logo" />
        <p className="mt-6 text-base text-slate-500 md:mt-0">
          Copyright &copy; {new Date().getFullYear()} {companyName}. All
          rights reserved.
        </p>
      </Container>
    </footer>
  )
}

import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'


export class SendEmailModalProps {
  emailTitle: Unknown;
  setEmailTitle: Unknown;
  emailBody: Unknown;
  setEmailBody: Unknown;
  emailRecipient: Unknown;
  setEmailRecipient: Unknown;
}


export function SendEmailModal(props: ReceiptModalProps) {
  const { emailTitle, setEmailTitle, emailBody, setEmailBody, emailRecipient, setEmailRecipient, handleSubmit,
    show, setShow } = props;

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShow}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-6 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-lg sm:max-w-sm sm:p-6">
                <div className="w-full mt-3 md:max-w-lg sm:max-w-sm">
                  <label className="whitespace-nowrap pr-3 text-md font-medium text-gray-900" htmlFor="text-input">Title</label>
                  <input
                    type="text"
                    name="text-name"
                    id="text-id"
                    className="block w-full rounded-md border-gray-300 shadow-sm mt-1 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={emailTitle}
                    onChange={(e) => setEmailTitle(e.target.value)}
                    required
                    placeholder="Refund Request"
                  />
                </div>
                <div className="w-full mt-3 md:max-w-lg sm:max-w-sm">
                  <label className="whitespace-nowrap pr-3 text-md font-medium text-gray-900" htmlFor="text-input">Body</label>
                  <textarea
                    type="text"
                    name="text-name"
                    id="text-id"
                    className="block w-full rounded-md border-gray-300 shadow-sm mt-1 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={emailBody}
                    onChange={(e) => setEmailBody(e.target.value)}
                    required
                    rows="10"
                    cols="60"
                  />
                </div>
                <div className="w-full mt-3 md:max-w-lg sm:max-w-sm">
                  <label className="whitespace-nowrap pr-3 text-md font-medium text-gray-900" htmlFor="text-input">Receipient</label>
                  <input
                    type="text"
                    name="text-name"
                    id="text-id"
                    className="block w-full rounded-md border-gray-300 shadow-sm mt-1 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder={emailRecipient}
                    onChange={(e) => setEmailRecipient(e.target.value)}
                    required
                    placeholder="refund@example.com"
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {handleSubmit();}}
                  className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={() => {setShow(false); setEmailTitle(""); setEmailBody(null); setEmailRecipient("");}}
                  className="mt-3 ml-4 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-400 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

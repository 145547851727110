import { Footer } from "components/Footer";
import { Container } from "components/Container";
import { Table } from "components/Table";
import { Header } from "components/Header";
import { StatsWithSharedBorders } from "components/StatsWithSharedBorders";
import { getUserById, getProductsInSameGroup, getProductsInSameEmail, editRefundStatus } from "services/firebase";
import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "services/firebase";
import { parseSlashedDate, formatDate, daysAgo, epochSecToDate } from "utils/time";


import toast from "react-hot-toast";
import { AdminLightSidebar } from "components/AdminLightSidebar";

export function AdminSearch() {
  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState("");
  const [queryType, setQueryType] = useState("");
  const [searchProductInput, setSearchProductInput] = useState("");
  const [searchEmailInput, setSearchEmailInput] = useState("");

  useEffect(() => onAuthStateChanged(auth, (user) => {
    if (!user) {
      toast.error(`Please login first to access data.`);
      // redirect
      window.location = "/sign-in";
      return;
    }
    getUserById(user.uid).then((user) => {
      if (!user.is_admin) {
        toast.error(`You are not authorized to access this page.`);
        window.location = "/";
        return;
      }
    });
  }), []);

  const processProducts = (fetchedProducts) => {
    if (!fetchedProducts) {
      toast.error(`Cannot fetch products.`);
      return;
    }

    fetchedProducts.map((product) => {
      if (product.status === "refund-available") {
        product.status = "Available";
        product.backgroundColor = "#0080003d";
      } else if (product.status === "refund-pending") {
        product.status = "Pending";
        product.backgroundColor = "#0080003d";
      } else if (product.status === "refund-complete") {
        product.status = "Completed";
        product.backgroundColor = "#0080003d";
      } else if (product.status === "active") {
        product.status = "Active";
      } else if (product.status === "invalid") {
        product.status = "Invalid";
      } else if (product.status === "inactive") {
        product.status = "Inactive";
      } else if (product.status === "abnormal-price") {
        product.status = "Abnormal Price";
      } else if (product.status === "low-title-score") {
        product.status = "Low Title Similarity";
      } else if (product.status === "unknown-seller") {
        product.status = "Unknown Seller";
      } else if (product.status === "spec-mismatch") {
        product.status = "Spec Mismatch";
      } else if (product.status === "expired") {
        product.status = "Expired";
      } else if (product.status === "no-matching") {
        product.status = "No Matching";
      } else if (product.status === "need-attention") {
        product.status = "Need Attention";
      } else if (product.status === "unsupported-currency") {
        product.status = "Other Currency " + product.currency;
      }

      product.spec = "";
      if (product.color !== undefined && product.color !== "" && product.color !== "NA") {
        product.spec += " " + product.color;
      }
      if (product.size !== undefined && product.size !== "" && product.size !== "NA") {
        product.spec += " " + product.size;
      }
      if (product.dimensions !== undefined && product.dimensions !== "" && product.dimensions !== "NA") {
        product.spec += " " + product.dimensions;
      }
      product.spec = product.spec.trim();

      if (product.product_link) {
        product.link = "link";
      }

      product.display_name = product.product_name
      if (product.display_name.length > 60) {
          product.display_name = product.display_name.substring(0, 60) + "...";
      }

      if (product.purchase_time === undefined || product.purchase_time === "NA") {
        product.purchase_time = "";
      } else {
        var dateObj = parseSlashedDate(product.purchase_time);
        product.purchase_time = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
      }

      if (product.last_checked_timestamp <= 0) {
        product.last_checked_time = "";
      } else {
        var dateObj = epochSecToDate(product.last_checked_timestamp);
        product.last_checked_time = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
      }

      if (product.title_score !== undefined) {
        product.title_score = product.title_score.toFixed(2);
      }

      if (product.source === "gmail") {
        product.source = "Gmail";
      } else if (product.source === "manual") {
        product.source = "Manual";
      }
    });

    setProducts(fetchedProducts);
  };

  const handleSearch = (type, val) => {
    var retrieveFunc = null;
    if (type === "same_product") {
      retrieveFunc = getProductsInSameGroup;
    } else if (type === "same_email") {
      retrieveFunc = getProductsInSameEmail;
      const firstUnderscoreIdx = val.indexOf('_');
      const secondUnderscoreIdx = val.indexOf('_', firstUnderscoreIdx + 1);
      if (secondUnderscoreIdx !== -1) {
        val = val.substring(0, secondUnderscoreIdx);
      }
    } else {
      toast.error(`Unknown search type.`);
      return;
    }

    retrieveFunc(val).then((fetchedProducts) => {
      if (fetchedProducts.length === 0) {
        toast("No product found");
        return;
      }
      processProducts(fetchedProducts);
    });
  };

  return (
    <>
      <div id="loader"></div>
      <main>
        <AdminLightSidebar>
          <div className="relative pb-20 sm:py-5">
            <Container className="relative lg:pt-2">
              {" "}
              <Header title={"Search"} content={"The first input box let you submit a product id and search for all products that are same with this product. " +
              "The second input box let you submit an email id and search for all products that parsed from this email."} />{" "}
            </Container>
            <div className="FormField pt-5">
              <input
                type="email" placeholder="Product ID" value={searchProductInput} onChange={(e) => setSearchProductInput(e.target.value)}
                className="ml-8 mt-4 w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              <button type="auth" class="mt-4 ml-3 inline-flex rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm
              hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={() => {handleSearch("same_product", searchProductInput)}}>Submit</button>
              <input
                type="email" placeholder="Email ID" value={searchEmailInput} onChange={(e) => setSearchEmailInput(e.target.value)}
                className="ml-8 mt-4 w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              <button type="auth" class="mt-4 ml-3 inline-flex rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm
              hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={() => {handleSearch("same_email", searchEmailInput)}}>Submit</button>
            </div>
            <Container className="relative lg:pt-2">
              {" "}
              <Table
                idField="id"
                rows={products}
                columns={[
                  {
                    attribute: "email",
                    attributeName: "email",
                    width: 10,
                  },
                  {
                    attribute: "purchase_time",
                    attributeName: "purchase time",
                    width: 10,
                  },
                  {
                    attribute: "display_name",
                    attributeName: "receipt title",
                    width: 50,
                  },
                  {
                    attribute: "spec",
                    attributeName: "spec",
                    width: 20,
                  },
                  {
                    attribute: "crawled_product_title",
                    attributeName: "matched title",
                    width: 50,
                  },
                  {
                    attribute: "title_score",
                    attributeName: "title similarity",
                    width: 10,
                  },
                  {
                    attribute: "seller_name",
                    attributeName: "retailer",
                    width: 20,
                  },
                  {
                    attribute: "link",
                    attributeName: "link",
                    width: 20,
                    onClick: (row) => {window.open(row.product_link, '_blank').focus();},
                  },
                  {
                    attribute: "unit_price",
                    attributeName: "purchased price",
                    width: 5,
                  },
                  {
                    attribute: "last_checked_price",
                    attributeName: "current price",
                    width: 5,
                  },
                  {
                    attribute: "source",
                    attributeName: "source",
                    width: 5,
                  },
                  {
                    attribute: "status",
                    attributeName: "status",
                    width: 20,
                    type: "ProductStatusDropdown",
                    onClick: (row, val) => {
                      row.status = val;
                      if (val === "Available") {
                        val = "refund-available";
                      } else if (val === "Pending") {
                        val = "refund-pending";
                      } else if (val === "Completed") {
                        val = "refund-complete";
                      } else if (val === "Active") {
                        val = "active";
                      } else if (val === "Need Attention") {
                        val = "need-attention";
                      } else if (val === "Invalid") {
                        val = "invalid";
                      } else if (val === "Spec Mismatch") {
                        val = "spec-mismatch";
                      } else if (val === "Low Title Similarity") {
                        val = "low-title-score";
                      } else if (val === "Unknown Seller") {
                        val = "unknown-seller";
                      } else if (val === "Abnormal Price") {
                        val = "abnormal-price";
                      } else if (val === "No Matching") {
                        val = "no-matching";
                      } else if (val === "Expired") {
                        val = "expired";
                      } else {
                        return;
                      }
                      editRefundStatus(row.docRef, val);
                    },
                  },
                  {
                    attribute: "last_checked_time",
                    attributeName: "last checked time",
                    width: 10,
                  },
                  {
                    attribute: "docRef",
                    attributeName: "id",
                    width: 5,
                    onClick: (row) => {window.open("/view-email/" + row.docRef.substring(0, row.docRef.lastIndexOf('_')), '_blank').focus();},
                  },
                ]}
              />{" "}
            </Container>
          </div>
          <Footer companyName="pap!" />
        </AdminLightSidebar>
      </main>
    </>
  );
}

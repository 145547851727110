import "./App.css";
import { Toaster } from "react-hot-toast";
import "./styles/tailwind.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import { AdminRefunds } from "./pages/AdminRefunds";
import { AdminOfficialRefunds } from "./pages/AdminOfficialRefunds";
import { AdminProducts } from "./pages/AdminProducts";
import { AdminEmails } from "./pages/AdminEmails";
import { Settings } from "./pages/Settings";
import { SignIn } from "./pages/SignIn";
import { OneClickSignupSuccess } from "./pages/OneClickSignupSuccess";
import { OneClickSignupPhoneNumberV2 } from "./pages/OneClickSignupPhoneNumberV2";
import { OneClickSignupRetry } from "./pages/OneClickSignupRetry";
import { OneClickSignup } from "./pages/OneClickSignup";
import { AdminSignIn } from "./pages/AdminSignIn";
import { AdminUsers } from "./pages/AdminUsers";
import { AdminUserStats } from "./pages/AdminUserStats";
import { AdminEmailViewer } from "./pages/AdminEmailViewer";
import { AdminSearch } from "./pages/AdminSearch";
import { RedirectPhantomPage } from "./pages/Redirect";
import { OutlookRedirectPhantomPage } from "./pages/OutlookRedirect";
import { UnsupportedBrowser } from "./pages/UnsupportedBrowser";
import { GetUserAgent } from "./pages/GetUserAgent";
import { TestSignupFlow } from "./pages/TestSignupFlow";
import { PapTravelArrow } from "./pages/PapTravelArrow";
import { TravelArrowStripePayment } from "./pages/TravelArrowStripePayment";
import { Receipts } from "./pages/Receipts";
import { StripePayment } from "./pages/StripePayment";

export default function App() {
  return (
    <div className="App">
      <Toaster
        position="top-center"
        gutter={8}
        toastOptions={{
          duration: 5000,
        }}
      />
      <Router>
        <Routes>
          <Route exact path="/one-click-sign-in" element={<OneClickSignup />} />{" "}
          <Route exact path="/sign-in" element={<SignIn />} />{" "}
          <Route exact path="/receipts" element={<Receipts />} />{" "}
          <Route exact path="/signup-success" element={<OneClickSignupSuccess />} />{" "}
          <Route exact path="/v2/signup-phone-number" element={<OneClickSignupPhoneNumberV2 />} />{" "}
          <Route exact path="/test-signup-flow" element={<TestSignupFlow />} />{" "}
          <Route exact path="/signup-retry" element={<OneClickSignupRetry />} />{" "}
          <Route exact path="/admin-users" element={<AdminUsers />} />{" "}
          <Route exact path="/admin-sign-in" element={<AdminSignIn />} />{" "}
          <Route exact path="/admin-products" element={<AdminProducts />} />{" "}
          <Route exact path="/admin-official-refunds" element={<AdminOfficialRefunds />} />{" "}
          <Route exact path="/admin-refunds" element={<AdminRefunds />} />{" "}
          <Route exact path="/admin-emails" element={<AdminEmails />} />{" "}
          <Route exact path="/admin-search" element={<AdminSearch />} />{" "}
          <Route exact path="/admin-user-stats" element={<AdminUserStats />} />{" "}
          <Route exact path="/settings" element={<Settings />} />
          <Route exact path="/join" element={<RedirectPhantomPage />} />
          <Route exact path="/unsupported-browser" element={<UnsupportedBrowser />} />
          <Route exact path="/instagram" element={<UnsupportedBrowser />} />
          <Route exact path="/get-user-agent" element={<GetUserAgent />} />
          <Route exact path="/outlook-join" element={<OutlookRedirectPhantomPage />} />
          <Route exact path="/pap-travelarrow" element={<PapTravelArrow />} />
          <Route exact path="/stripe-payment" element={<StripePayment />} />
          <Route exact path="/travel-arrow-stripe-payment" element={<TravelArrowStripePayment />} />
          <Route path="/view-email/:mode?/:email_id/:type?" element={<AdminEmailViewer />} />
          {/* return root / to /dashboard URL directly */}
          <Route path="/" element={<Dashboard />} />
        </Routes>
      </Router>
    </div>
  );
}

import { Footer } from "components/Footer";
import { Container } from "components/Container";
import { Table } from "components/Table";
import { Header } from "components/Header";
import { getUsersWithPagination, getUserCountByStatus, getUserById } from "services/firebase";
import { AdminLightSidebar } from "components/AdminLightSidebar";
import { StatsWithSharedBorders } from "components/StatsWithSharedBorders";
import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from 'services/firebase';
import { formatDate, daysAgo, epochSecToDate } from "utils/time";

import toast from "react-hot-toast";

export function AdminUsers() {
  const pageSize = 20;
  const [lastUser, setLastUser] = useState(null);
  const [activeCnt, setActiveCnt] = useState(0);
  const [errorCnt, setErrorCnt] = useState(0);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        toast.error(`Please login first to access data.`);
        window.location="/admin-sign-in";
        return;
      }
      getUserById(user.uid).then((user) => {
        if (!user.is_admin) {
          toast.error(`You are not authorized to access this page.`);
          window.location="/";
          return;
        }
      });
      getUserCountByStatus().then((userCnts) => {
        setActiveCnt(userCnts.active);
        setErrorCnt(userCnts.error);
      });

      getUsersWithPagination(pageSize, lastUser).then((fetchedUsers) => {
        fetchedUsers = processFetchedUsers(fetchedUsers);
        setUsers(fetchedUsers);
      });
    });
  }, []);

  const processFetchedUsers = (fetchedUsers) => {
    if (!fetchedUsers || fetchedUsers.length === 0) {
      toast(`No more users.`);
      return [];
    }

    setLastUser(fetchedUsers[fetchedUsers.length-1]);
    fetchedUsers = fetchedUsers.map(doc =>({docRef: doc.ref.id, ...doc.data()}));

    fetchedUsers.map((user) => {
      if (user.status === "active") {
        user.status = "Active";
      } else if (user.status === "inactive") {
        user.status = "Inactive";
      } else if (user.status === "error") {
        user.status = "Error";
        user.backgroundColor = "#8019003d";
      }

      if (user.updated_at !== undefined && user.updated_at > 0) {
        var dateObj = epochSecToDate(user.updated_at);
        user.signedup_at = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
      }

      if (user.last_checked_timestamp <= 0) {
        user.last_checked_time = "";
      } else {
        var dateObj = epochSecToDate(user.last_checked_timestamp);
        user.last_checked_time = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
      }
    });

    return fetchedUsers;
  };

  const loadMore = () => {
    getUsersWithPagination(pageSize, lastUser).then((fetchedUsers) => {
      const loadedUsers = processFetchedUsers(fetchedUsers);
      setUsers(users.concat(loadedUsers));
    });
  };

  return (
    <>
      <div id="loader"></div>
      <main>
        <AdminLightSidebar>
          <div className="relative pb-20 sm:py-5">
            <Container className="relative lg:pt-8">
              {" "}
              <Header title={"All Users"} content={"Info on all users registered"} />{" "}
            </Container>
            <Container className="relative lg:pt-8">
              <StatsWithSharedBorders
                heading="Last 30 days"
                stats={[
                  {
                    name: "Active Users",
                    stat: activeCnt,
                  },
                  {
                    name: "Unauthorized Users",
                    stat: errorCnt,
                  }
                ]}
              />
            </Container>
            <Container className="relative lg:pt-8">
              {" "}
              <Table
                idField="id"
                rows={users}
                columns={[
                  {
                    attribute: "name",
                    attributeName: "Name",
                    width: 10,
                  },
                  {
                    attribute: "email",
                    attributeName: "Email",
                    width: 10,
                  },
                  {
                    attribute: "status",
                    attributeName: "Status",
                    width: 10,
                  },
                  {
                    attribute: "last_checked_time",
                    attributeName: "Last Checked Time",
                    width: 10,
                  },
                  {
                    attribute: "signedup_at",
                    attributeName: "Signup Time",
                    width: 10,
                  },
                  {
                    attribute: "is_admin",
                    attributeName: "Admin",
                    width: 10,
                  },
                  {
                    attribute: "docRef",
                    attributeName: "User ID",
                    width: 10,
                  },
                ]}
              />{" "}
            </Container>
          </div>
          <button type="auth" class="ml-8 inline-flex rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={loadMore}>Load More</button>
          <Footer companyName="pap!" />
        </AdminLightSidebar>
      </main>
    </>
  );
}

import { Footer } from "components/Footer";
import { Container } from "components/Container";
import React, { useState, useEffect } from "react";

import toast from "react-hot-toast";

export function OneClickSignupRetry() {
  useEffect(() => {
  }, []);

  return (
    <>
      <div id="loader"></div>
      <main>
        <div className="relative">
          <Container className="h-screen flex items-center justify-center">
            <div className="border-b border-gray-200 border-none">
              <h2 className="text-2xl font-bold pb-3 leading-6 text-gray-900 font-poppins">{"Something went wrong :("}</h2>
              <p className="mt-1 max-w-4xl text-lg text-gray-800 font-poppins">{"It looks like we still don't have the permissions required to cast our magic."}</p>
              <p className="mt-1 max-w-4xl text-lg text-gray-800 font-poppins">{"Please click the following button to retry and make sure you toggle the permissions box."}</p>
              <div className="my-8 sm:mx-auto sm:w-full md:max-w-md rounded-lg border border-gray-100 shadow-sm">
                <div className="bg-white py-10 px-4 shadow sm:rounded-lg sm:px-10">
                  <form className="space-y-6"
                    onSubmit={(event) => {
                      event.preventDefault();
                      const state = (Math.random() + 1).toString(36).substring(2);
                      window.location.href = "https://accounts.google.com/o/oauth2/auth?client_id=69825809285-j0hs7rhus3oougluep0ns6jcqub8nkq8.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fportal.joinpap.com%2Fgoogle_oauth_callback_v2&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&access_type=offline&response_type=code&approval_prompt=force&state=" + state;
                    }}
                  >
                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md border border-transparent bg-yellow-300 py-2 px-4 text-yellow-900 text-lg font-semibold shadow-sm hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Google Sign in
                      </button>
                    </div>
                  </form>
                  <form className="space-y-6 pt-6"
                    onSubmit={(event) => {
                      event.preventDefault();
                      window.location.href = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=436b867e-58fe-4cf1-96a7-1a90f36c000c&redirect_uri=https%3A%2F%2Fportal.joinpap.com%2Foutlook_oauth_callback_v2&scope=openid+profile+offline_access+https%3A%2F%2Fgraph.microsoft.com%2FMail.Read+https%3A%2F%2Fgraph.microsoft.com%2FUser.Read&access_type=offline&response_type=code&approval_prompt=force";
                    }}
                  >
                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md border border-transparent bg-yellow-300 py-2 px-4 text-yellow-900 text-lg font-semibold shadow-sm hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Outlook Sign in
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <p className="mt-1 max-w-4xl text-lg text-gray-800 font-poppins">{"- pap! team"}</p>
            </div>
          </Container>
        </div>
        <Footer companyName="pap!" />
      </main>
    </>
  );
}

import { Footer } from "components/Footer";
import { Container } from "components/Container";
import React, { useState, useEffect } from "react";

import toast from "react-hot-toast";

export function OneClickSignupSuccess() {
  useEffect(() => {
  }, []);

  return (
    <>
      <div id="loader"></div>
      <main>
        <div className="relative">
          <Container className="h-screen flex items-center justify-center">
            <div className="border-b border-gray-200 border-none">
              <h2 className="text-2xl font-bold pb-3 leading-6 text-gray-900 font-poppins">{"You are all set!"}</h2>
              <p className="mt-1 max-w-4xl text-lg text-gray-800 font-poppins">{"Now just relax and wait for the good news from us."}</p>
              <p className="mt-1 max-w-4xl text-lg text-gray-800 font-poppins">{"- pap! team"}</p>
            </div>
          </Container>
        </div>
        <Footer companyName="pap!" />
      </main>
    </>
  );
}

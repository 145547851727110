import { Footer } from "components/Footer";
import { EditForm, EditEntryType } from "components/EditForm";
import { Container } from "components/Container";
import { Header } from "components/Header";
import { editUser, getUserById } from "services/firebase";
import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "services/firebase";

import toast from "react-hot-toast";
import { LightSidebar } from "components/LightSidebar";

export function Settings() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        toast.error(`Please login first to access data.`);
        window.location = "/sign-in";
        return;
      }
      // console.log(user.uid);
      getUserById(user.uid).then((fetchedUser) => {
        if (!fetchedUser) {
          toast.error(`Cannot find the Users profiles.`);
          return;
        }
        setCurrentUser(fetchedUser);
      });
    });
  }, []);

  const loadAuthPage = () => {
    // TODO: check state
    const state = (Math.random() + 1).toString(36).substring(2);
    window.location.href = "https://accounts.google.com/o/oauth2/auth?client_id=69825809285-j0hs7rhus3oougluep0ns6jcqub8nkq8.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fportal.joinpap.com%2Fgoogle_oauth_callback_v2&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&access_type=offline&response_type=code&approval_prompt=force&user_id=" + currentUser.email + "&state=" + state;
  };

  return (
    <>
      <div id="loader"></div>
      <main>
        <LightSidebar>
          <div className="relative pb-20 sm:py-5">
            <Container className="relative lg:pt-8">
              {" "}
              <Header
                title={"Settings"}
                content={"Configure your settings below"}
              />{" "}
            </Container>
            <Container className="relative">
              {" "}
              <EditForm
                editEntries={[
                  {
                    attribute: "name",
                    attributeName: "Name",
                    type: EditEntryType.Text,
                    isRequired: true,
                  },
                  {
                    attribute: "newsletter_preference",
                    attributeName: "Newsletter Preference",
                    type: EditEntryType.Radio,
                    options: ["Everyday", "Every Week", "Every Month"],
                    isRequired: true,
                  },
                  {
                    attribute: "linked_email",
                    attributeName: "Linked Email",
                    type: EditEntryType.Text,
                    isRequired: true,
                  }
                ]}
                entityObj={currentUser}
                onSubmitSuccess={(currentUser) => { editUser(currentUser) }}
              />{" "}
            </Container>
          </div>
          <button type="auth" class="ml-3 inline-flex rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={loadAuthPage}>Authorize</button>
          <Footer companyName="pap!" />
        </LightSidebar>
      </main>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import TravelArrowCheckoutForm from "components/TravelArrowStripePaymentForm";


const stripePromise = loadStripe("pk_live_51Ozid0DYMoZqAQuZH7rU1tufIB7mE3EJc3oZx41mplJohhw0umvfd6itIPSJtspFhReeuT7yoSzVl7Ha69OO487L00eDERCDv2");

export function TravelArrowStripePayment() {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const urlClientSecret = new URLSearchParams(window.location.search).get(
      "client_secret"
    );
    setClientSecret(urlClientSecret);
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <TravelArrowCheckoutForm />
        </Elements>
      )}
    </>
  );
}
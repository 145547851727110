import { initializeApp } from "firebase/app";
import toast from 'react-hot-toast';
import { getFirestore, runTransaction, query, getDoc, getDocs, addDoc, updateDoc, collection, where,
  documentId, doc, or, and, limit, startAfter, orderBy, getCountFromServer } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { ref as storageReference, uploadBytesResumable, getStorage, getDownloadURL } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { StoredFile, StoredFileState } from "data/common";

import { AdminUsers } from 'data/AdminUsers';
import { Users } from 'data/Users';
import { Receipts } from 'data/Receipts';
import { Refunds } from 'data/Refunds';

import { FirebaseConfig } from 'services/firebase.config';


// Initialize Firebase
const app = initializeApp(FirebaseConfig);
const db = getFirestore(app);
export const auth = getAuth(app);
const storage = getStorage(app);
export const functions = getFunctions(app);
export const uploadFile = (ownerId: String, fileBucket: String, file: Blob | Uint8Array | ArrayBuffer, setPercent, onSucceed) => {
  if (!ownerId || !file || !file.name || !fileBucket) {
    return;
  }
  const uploadedFileName = ownerId + "#" + file.name;
  const storageRef = storageReference(storage, "/" + fileBucket + "/" + uploadedFileName);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const percent = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      setPercent(percent);
    },
    (err) => toast.error(err),
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        const storedFile: StoredFile = {
          filename: file.name,
          file_path: url,
          created_at: new Date().toISOString().slice(0, 19) + "Z",
          state: StoredFileState.Uploaded,
        };
        onSucceed(storedFile);
      });
    }
  );
}


// AdminUsers CRUD
export const getAdminUsersByIds = async (entityIds) => {
  try {
    const q = query(collection(db, "admin_users"), where(documentId(), "in", entityIds));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      return null;
    }
    return docs.docs.map(doc => doc.data());
  } catch (err) {
    toast.error(err.message);
    return null;
  }
}
export const getAdminUsersById = async (entityId) => {
  try {
    const q = query(collection(db, "admin_users"), where(documentId(), "==", entityId));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      return null;
    }
    return docs.docs[0].data();
  } catch (err) {
    toast.error(err.message);
    return null;
  }
}
export const getAllAdminUsers = async () => {
  const q = query(collection(db, "admin_users"));
  const allAdminUsers = await getDocs(q);
  return allAdminUsers.docs.map(doc => doc.data());
}
export const editAdminUsers = async (adminUsers: AdminUsers) => {
  if (adminUsers && adminUsers.user_name) {
    const existUsernameQ = query(collection(db, "admin_users"), where("user_name", "==", adminUsers.user_name));
    const existUsernameDocs = await getDocs(existUsernameQ);
    if (existUsernameDocs.docs.length > 0) {
      const existUsernameDoc: AdminUsers = existUsernameDocs.docs[0].data();
      if (existUsernameDoc.id !== adminUsers.id) {
        toast.error("This User Name has been taken by another user. Please use a different user name.");
        throw new Error("duplicate_username");
      }
    }
  }

  const entityId = adminUsers.id;
  const timeNow = new Date().toISOString().slice(0, 19) + "Z";
  adminUsers.created_at = adminUsers.created_at ? adminUsers.created_at : timeNow;
  adminUsers.update_at = timeNow;

  const adminUsersSerilized = JSON.parse(JSON.stringify(adminUsers));
  if (entityId) {
    await runTransaction(db, async (transaction) => {
      const sfDocRef = doc(db, "admin_users", entityId);
      transaction.set(sfDocRef, adminUsersSerilized);
    });
  } else {
    await addDoc(collection(db, "admin_users"), adminUsersSerilized);
  }
}

    
// Users CRUD
export const getUsersByIds = async (entityIds) => {
  try {
    const q = query(collection(db, "users"), where(documentId(), "in", entityIds));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      return null;
    }
    return docs.docs.map(doc => doc.data());
  } catch (err) {
    toast.error(err.message);
    return null;
  }
}
export const getUserById = async (entityId) => {
  try {
    const q = query(collection(db, "users"), where(documentId(), "==", entityId));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      return null;
    }
    return docs.docs[0].data();
  } catch (err) {
    toast.error(err.message);
    return null;
  }
}

export const getAllUsers = async () => {
  const q = query(collection(db, "users"));
  const allUsers = await getDocs(q);
  return allUsers.docs.map(doc => ({docRef: doc.ref.id, ...doc.data()}));
}

export const getUsersWithPagination = async (size, lastDoc) => {
  var q = query(collection(db, "users"), orderBy("updated_at", "desc"), limit(size));
  if (lastDoc !== null) {
    q = query(collection(db, "users"), orderBy("updated_at", "desc"), startAfter(lastDoc), limit(size));
  }
  const resUsers = await getDocs(q);
  return resUsers.docs;
}

export const getUserCountByStatus = async () => {
  var q = query(collection(db, "users"), where("status", "==", "active"));
  var snapshot = await getCountFromServer(q);
  const activeCnt = snapshot.data().count;
  q = query(collection(db, "users"), where("status", "==", "error"));
  snapshot = await getCountFromServer(q);
  const errorCnt = snapshot.data().count;
  return {"active": activeCnt, "error": errorCnt};
}

export const editUser = async (user: Users) => {
  if (user && user.user_name) {
    const existUsernameQ = query(collection(db, "users"), where("user_name", "==", user.user_name));
    const existUsernameDocs = await getDocs(existUsernameQ);
    if (existUsernameDocs.docs.length > 0) {
      const existUsernameDoc: Users = existUsernameDocs.docs[0].data();
      if (existUsernameDoc.id !== user.id) {
        toast.error("This User Name has been taken by another user. Please use a different user name.");
        throw new Error("duplicate_username");
      }
    }
  }

  const entityId = user.id;
  const timeNow = new Date().toISOString().slice(0, 19) + "Z";
  user.created_at = user.created_at ? user.created_at : timeNow;
  user.updated_at = Date.now() / 1000 | 0;
  user.status = "new";
  user.last_checked_timestamp = 0;

  const serializedUser = JSON.parse(JSON.stringify(user));
  if (entityId) {
    await runTransaction(db, async (transaction) => {
      const sfDocRef = doc(db, "users", entityId);
      transaction.set(sfDocRef, serializedUser);
    });
  } else {
    await addDoc(collection(db, "users"), serializedUser);
  }
}

    
// Refunds CRUD

export const getProductsByEmail = async (email) => {
  try {
    const q = query(collection(db, "parsed_products"), where("email", "==", email));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      return null;
    }
    return docs.docs.map(doc => doc.data());
  }
  catch (err) {
    toast.error(err.message);
    return null;
  }
}

export const getProductsByStatus = async (statusLst) => {
  var statusFilter = statusLst.map(status => {
      return where("status", "==", status);
  });
  try {
    const q = query(collection(db, "parsed_products"), and(where("is_test", "==", false), or(...statusFilter)));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      return null;
    }
    return docs.docs.map(doc => ({docRef: doc.ref.id, ...doc.data()}));
  }
  catch (err) {
    toast.error(err.message);
    return null;
  }
}

export const getOfficialRefundProducts = async () => {
  const q = query(collection(db, "parsed_products"), and(where("title_score", ">", 0.8), where("status", "==", "refund-available")));
  const docs = await getDocs(q);
  if (docs.docs.length === 0) {
    return null;
  }
  return docs.docs.map(doc =>
    ({docRef: doc.ref.id, ...doc.data()})
  ).filter(product => {
      return true;
    }
  );
}

export const addProduct = async (productObj) => {
  productObj.timestamp = Date.now() / 1000 | 0;
  productObj.status = "active";
  productObj.source = "manual";
  productObj.last_checked_timestamp = 0;

  return await addDoc(collection(db, "parsed_products"), productObj).then((docRef) => {
    return true;
  }).catch((e) => {
    console.log("[addProductsByUserId] error", e);
    return false;
  });
}

export const getRefundsByIds = async (entityIds) => {
  try {
    const q = query(collection(db, "parsed_products"), where(documentId(), "in", entityIds));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      return null;
    }
    return docs.docs.map(doc => ({docRef: doc.ref.id, ...doc.data()}));
  } catch (err) {
    toast.error(err.message);
    return null;
  }
}

export const getRefundsById = async (entityId) => {
  try {
    const q = query(collection(db, "parsed_products"), where(documentId(), "==", entityId));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      return null;
    }
    return docs.docs[0].data();
  } catch (err) {
    toast.error(err.message);
    return null;
  }
}

export const getRefundCountByStatus = async () => {
  var q = query(collection(db, "parsed_products"), where("status", "==", "active"));
  var snapshot = await getCountFromServer(q);
  const activeCnt = snapshot.data().count;
  q = query(collection(db, "parsed_products"), where("status", "in", ["refund-available", "refund-pending", "refund-complete"]));
  snapshot = await getCountFromServer(q);
  const refundCnt = snapshot.data().count;
  q = query(collection(db, "parsed_products"), where("status", "in", ["abnormal-price", "low-title-score", "spec-mismatch"]));
  snapshot = await getCountFromServer(q);
  const mismatchCnt = snapshot.data().count;
  q = query(collection(db, "parsed_products"), where("status", "==", "unknown-seller"));
  snapshot = await getCountFromServer(q);
  const unknownSellerCnt = snapshot.data().count;
  q = query(collection(db, "parsed_products"), where("status", "in", ["invalid", "unsupported-currency", "no-longer-available"]));
  snapshot = await getCountFromServer(q);
  const invalidCnt = snapshot.data().count;
  q = query(collection(db, "parsed_products"), where("status", "==", "no-matching"));
  snapshot = await getCountFromServer(q);
  const nomatchingCnt = snapshot.data().count;
  return {"active": activeCnt, "refund": refundCnt, "mismatch": mismatchCnt, "unknowseller": unknownSellerCnt, "invalid": invalidCnt, "nomatching": nomatchingCnt};
}

export const getAllRefunds = async () => {
  var q = query(collection(db, "parsed_products"));
  const allRefunds = await getDocs(q);
  return allRefunds.docs.map(doc => ({docRef: doc.ref.id, ...doc.data()}));
}

export const getRefundsWithPagination = async (categoryType, status, email, size, lastDoc) => {
  var queryOps = [orderBy("purchase_time_sec", "desc"), limit(size)];
  if (categoryType === "") {
    queryOps.push(where("is_test", "==", false));
    if (status === "") {
      queryOps.push(where("status", "in", ["refund-available", "refund-pending", "refund-complete",
        "active", "abnormal-price", "low-title-score", "spec-mismatch", "need-attention", "no-matching"]));
    }
  } else if (categoryType === "invalid-only") {
    queryOps.push(where("is_test", "==", false));
    if (status === "") {
      queryOps.push(where("status", "in", ["inactive", "invalid", "unsupported-currency"]));
    }
  } else if (categoryType === "test-only") {
    queryOps.push(where("is_test", "==", true));
  } else if (categoryType === "expired") {
    queryOps.push(where("is_test", "==", false));
    if (status === "") {
      queryOps.push(where("status", "==", "expired"));
    }
  }

  if (status !== "") {
    queryOps.push(where("status", "==", status));
  }

  if (email !== "") {
    queryOps.push(where("email", "==", email));
  }
  if (lastDoc !== null) {
    queryOps.push(startAfter(lastDoc));
  }
  var q = query(collection(db, "parsed_products"), ...queryOps);
  const resProducts = await getDocs(q);
  return resProducts.docs;
}

export const getProductsInSameGroup = async (productId) => {
  const docSnap = await getDoc(doc(db, "parsed_products", productId));
  if (!docSnap.exists()) {
    return [];
  }
  const papProductId = docSnap.data().pap_product_id

  const q = query(collection(db, "parsed_products"), where("pap_product_id", "==", papProductId));
  const productsInSameGroup = await getDocs(q);
  return productsInSameGroup.docs.map(doc => ({docRef: doc.ref.id, ...doc.data()}));
}

export const getProductsInSameEmail = async (emailId) => {
  const q = query(collection(db, "parsed_products"), where("source_email", "==", emailId));
  const validRefunds = await getDocs(q);
  return validRefunds.docs.map(doc => ({docRef: doc.ref.id, ...doc.data()}));
}

export const editRefundStatus = async (docRef: String, status: String) => {
  updateDoc(doc(db, "parsed_products", docRef), {status: status})
}

export const editRefundAndPapProductStatus = async (docRef: String, status: String) => {
  const docSnap = await getDoc(doc(db, "parsed_products", docRef));
  if (!docSnap.exists()) {
    return [];
  }
  const papProductId = docSnap.data().pap_product_id

  updateDoc(doc(db, "products", papProductId), {last_checked_status: status})
  updateDoc(doc(db, "parsed_products", docRef), {status: status})
}

export const editRefunds = async (refunds: Refunds) => {
  if (refunds && refunds.user_name) {
    const existUsernameQ = query(collection(db, "refunds"), where("user_name", "==", refunds.user_name));
    const existUsernameDocs = await getDocs(existUsernameQ);
    if (existUsernameDocs.docs.length > 0) {
      const existUsernameDoc: Refunds = existUsernameDocs.docs[0].data();
      if (existUsernameDoc.id !== refunds.id) {
        toast.error("This User Name has been taken by another user. Please use a different user name.");
        throw new Error("duplicate_username");
      }
    }
  }

  const entityId = refunds.id;
  const timeNow = new Date().toISOString().slice(0, 19) + "Z";
  refunds.created_at = refunds.created_at ? refunds.created_at : timeNow;
  refunds.update_at = timeNow;

  const refundsSerilized = JSON.parse(JSON.stringify(refunds));
  if (entityId) {
    await runTransaction(db, async (transaction) => {
      const sfDocRef = doc(db, "refunds", entityId);
      transaction.set(sfDocRef, refundsSerilized);
    });
  } else {
    await addDoc(collection(db, "refunds"), refundsSerilized);
  }
}

export const getAllEmailsWithPagination = async (size, lastDoc) => {
  var q = query(collection(db, "user_emails"), orderBy("threadId", "desc"), limit(size));
  if (lastDoc !== null) {
    q = query(collection(db, "user_emails"), orderBy("threadId", "desc"), startAfter(lastDoc), limit(size));
  }
  const resEmails = await getDocs(q);
  return resEmails.docs;
}

export const getEmailsByEmailAddressWithPagination = async (emailAddr, size, lastDoc) => {
  var q = query(collection(db, "user_emails"),
    where("source_email", "==", emailAddr),
    orderBy("threadId", "desc"),
    limit(size));
  if (lastDoc !== null) {
    q = query(collection(db, "user_emails"),
      where("source_email", "==", emailAddr),
      orderBy("threadId", "desc"),
      startAfter(lastDoc),
      limit(size));
  }
  const resEmails = await getDocs(q);
  return resEmails.docs;
}

export const getEmailsByStatusWithPagination = async (status, size, lastDoc) => {
  var q = query(collection(db, "user_emails"),
    where("status", "==", status),
    orderBy("threadId", "desc"),
    limit(size));
  if (lastDoc !== null) {
    q = query(collection(db, "user_emails"),
      where("status", "==", status),
      orderBy("threadId", "desc"),
      startAfter(lastDoc),
      limit(size));
  }
  const resEmails = await getDocs(q);
  return resEmails.docs;
}

export const getEmailsByEmailAddressStatusWithPagination = async (emailAddr, status, size, lastDoc) => {
  var q = query(collection(db, "user_emails"),
    where("source_email", "==", emailAddr),
    where("status", "==", status),
    orderBy("threadId", "desc"),
    limit(size));
  if (lastDoc !== null) {
    q = query(collection(db, "user_emails"),
      where("source_email", "==", emailAddr),
      where("status", "==", status),
      orderBy("threadId", "desc"),
      startAfter(lastDoc),
      limit(size));
  }
  const resEmails = await getDocs(q);
  return resEmails.docs;
}

export const getAllEmails = async () => {
  const q = query(collection(db, "user_emails"));
  const allEmails = await getDocs(q);
  return allEmails.docs.map(doc =>({docRef: doc.ref.id, ...doc.data()}));
}

export const getEmailById = async (entityId, mode) => {
  try {
    var colName = "user_emails";
    if (mode === "all") {
      colName = "all_user_emails";
    } else if (mode === "flight") {
      colName = "user_flight_emails";
    }
    const q = query(collection(db, colName), where(documentId(), "==", entityId));

    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      return null;
    }
    return docs.docs[0].data();
  } catch (err) {
    toast.error(err.message);
    return null;
  }
}

export const getEmailCountByStatus = async () => {
  var q = query(collection(db, "user_emails"), where("status", "==", "new"));
  var snapshot = await getCountFromServer(q);
  const newCnt = snapshot.data().count;
  q = query(collection(db, "user_emails"), where("status", "in", ["processed", "error-no-price", "error-empty"]));
  snapshot = await getCountFromServer(q);
  const processedCnt = snapshot.data().count;
  q = query(collection(db, "user_emails"), where("status", "in", ["empty-email", "error-unknown"]));
  snapshot = await getCountFromServer(q);
  const errorCnt = snapshot.data().count;
  return {"new": newCnt, "processed": processedCnt, "error": errorCnt};
}

export const editEmailStatus = async (docRef: String, status: String) => {
  updateDoc(doc(db, "user_emails", docRef), {status: status})
}

export const getRefundTrackerByProductID = async (entityId) => {
  try {
    const q = query(collection(db, "refund_trackers"), where("product_id", "==", entityId));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      return null;
    }
    return docs.docs.map(doc => ({docRef: doc.ref.id, ...doc.data()}));
  } catch (err) {
    toast.error(err.message);
    return null;
  }
}

export const updateRefundTrackerByProductIDAdminID = async (entityId, refundTrackerObj) => {
  try {
    const timeNow = Date.now() / 1000 | 0;
    const q = query(collection(db, "refund_trackers"),
      and(where("product_id", "==", entityId), where("admin_id", "==", refundTrackerObj.admin_id)));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      const updateTrackerStr = JSON.stringify({
        product_id: entityId,
        admin_id: refundTrackerObj.admin_id,
        state: refundTrackerObj.state,
        communication_method: refundTrackerObj.communication_method,
        note: refundTrackerObj.note,
        created_at: timeNow,
        updated_at: timeNow,
      })
      const trackerSerialized = JSON.parse(updateTrackerStr);
      addDoc(collection(db, "refund_trackers"), trackerSerialized);
      createOperationLogs(entityId, "parsed_product", refundTrackerObj.admin_id, "new_refund_tracker", null, updateTrackerStr);
    } else {
      var updateObj = {
        updated_at: timeNow,
      };
      if (refundTrackerObj.state !== "" && docs.docs[0].data().state !== refundTrackerObj.state) {
        updateObj.state = refundTrackerObj.state;
      }
      if (refundTrackerObj.communication_method !== "" && docs.docs[0].data().communication_method !== refundTrackerObj.communication_method) {
        updateObj.communication_method = refundTrackerObj.communication_method;
      }
      if (refundTrackerObj.note !== "" && docs.docs[0].data().note !== refundTrackerObj.note) {
        updateObj.note = refundTrackerObj.note;
      }
      updateDoc(doc(db, "refund_trackers", docs.docs[0].ref.id), updateObj)
      createOperationLogs(entityId, "parsed_product", refundTrackerObj.admin_id, "update_refund_tracker", JSON.stringify(docs.docs[0].data()), JSON.stringify(updateObj));
    }
  } catch (err) {
    toast.error(err.message);
    return null;
  }
}

export const createOperationLogs = async (entityId, entityType, adminId, type, oldValue, newValue) => {
  try {
    const timeNow = Date.now() / 1000 | 0;
    const operationLogSerialized = JSON.parse(JSON.stringify({
      entity_id: entityId,
      entity_type: entityType,
      admin_id: adminId,
      type: type,
      old_value: oldValue,
      new_value: newValue,
      source: "admin_portal",
      created_at: timeNow,
    }));
    await addDoc(collection(db, "operation_logs"), operationLogSerialized);
  } catch (err) {
    toast.error(err.message);
    return null;
  }
}

import { Footer } from "components/Footer";
import { Container } from "components/Container";
import { Table } from "components/Table";
import { Header } from "components/Header";
import { auth, functions, getOfficialRefundProducts, getUserById, editRefundAndPapProductStatus, getRefundTrackerByProductID, updateRefundTrackerByProductIDAdminID, createOperationLogs } from "services/firebase";
import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { parseSlashedDate, parseDashDate, formatDate, daysAgo, epochSecToDate } from "utils/time";
import { adminIdToName } from "utils/consts";

import toast from "react-hot-toast";
import { AdminLightSidebar } from "components/AdminLightSidebar";
import { SendEmailModal } from "components/SendEmailModal";
import { RefundTrackerModal } from "components/RefundTrackerModal";

export function AdminOfficialRefunds() {
  const [refunds, setRefunds] = useState([]);
  const [adminId, setAdminId] = useState("");
  const [seed, setSeed] = useState(1);

  const [emailTitle, setEmailTitle] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [emailRecipient, setEmailRecipient] = useState("");
  const [currentRefundTrackerObj, setCurrentRefundTrackerObj] = useState(null);
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const [showRefundTrackerModal, setShowRefundTrackerModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  useEffect(() => onAuthStateChanged(auth, (user) => {
    if (!user) {
      toast.error(`Please login first to access data.`);
      // redirect
      window.location = "/sign-in";
      return;
    }
    getUserById(user.uid).then((firestoreUser) => {
      if (!firestoreUser.is_admin) {
        toast.error(`You are not authorized to access this page.`);
        window.location = "/";
        return;
      }

      setAdminId(user.uid);
    });
    getOfficialRefundProducts().then((fetchedProducts) => {
      if (!fetchedProducts) {
        toast(`No refunds found`);
        return;
      }

      fetchedProducts.map((product) => {
        if (product.status === "refund-available") {
          product.status = "Available";
        } else if (product.status === "refund-pending") {
          product.status = "Pending";
        } else if (product.status === "refund-complete") {
          product.status = "Completed";
        }

        product.spec = "";
        if (product.color !== undefined && product.color !== "" && product.color !== "NA") {
          product.spec += " " + product.color;
        }
        if (product.size !== undefined && product.size !== "" && product.size !== "NA") {
          product.spec += " " + product.size;
        }
        if (product.dimensions !== undefined && product.dimensions !== "" && product.dimensions !== "NA") {
          product.spec += " " + product.dimensions;
        }
        product.spec = product.spec.trim();

        if (product.product_link) {
          product.link = "link";
        }

        if (product.purchase_time === undefined || product.purchase_time === "NA") {
          product.purchase_time = "";
        } else if (product.purchase_time.includes('/')) {
          var dateObj = parseSlashedDate(product.purchase_time.replace(/[^0-9/-]/g, ''));
          product.receipt_time = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
        } else if (product.purchase_time.includes('-')) {
          dateObj = parseDashDate(product.purchase_time.replace(/[^0-9/-]/g, ''));
          product.receipt_time = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
        }

        if (product.email_time_sec <= 0) {
          product.email_time = "";
        } else {
          dateObj = epochSecToDate(product.email_time_sec);
          product.email_time = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
        }

        if (product.title_score !== undefined) {
          product.title_score = product.title_score.toFixed(2);
        }

        if (product.source === "gmail") {
          product.source = "Gmail";
        } else if (product.source === "outlook") {
          product.source = "Outlook";
        } else if (product.source === "manual") {
          product.source = "Manual";
        }
        product.refundTracker = null;
        product.refundTrackerID = "na";

        getRefundTrackerByProductID(product.docRef).then((res) => {
          if (res == null) {
            return;
          }
          product.refundTracker = res[0];
          product.refundTrackerID = adminIdToName(res[0].admin_id) + " (" + res[0].state + ")";
          reset();
        });
      });
      fetchedProducts = dedupProducts(fetchedProducts);
      setRefunds(fetchedProducts);
    });
  }), []);

  const dedupProducts = (products) => {
    const seenIds = new Set();
    const res = [];

    products.forEach(product => {
      if (product.is_test || (product.seller_name !== undefined && ["farfetch", "amazon eu s.a.r.l", "amazon"].includes(product.seller_name.toLowerCase()))) {
        return;
      }
      const dedupKey = product.pap_product_id + "|" + product.email;
      var dedupKey2 = "";
      if (product.item_number !== undefined && product.item_number !== "" && (product.item_number + "").toLowerCase() !== "na") {
        dedupKey2 = product.seller_name + "|" + product.email + "|" + product.item_number;
      }

      if (!seenIds.has(dedupKey) && (dedupKey2 === "" || !seenIds.has(dedupKey2))) {
          res.push(product);
      }

      seenIds.add(dedupKey);
      if (dedupKey2 !== "") {
        seenIds.add(dedupKey2);
      }
    });

    return res;
  };

  const loadSendEmailModal = async (row) => {
    setEmailTitle("Request for Refund Due to Price Adjustment Policy");
    setEmailBody(`Dear ${row.seller_name} Customer Service Team,

I hope this email finds you well. My name is {my_name}, and I recently made a purchase of ${row.product_name} from your store.

Upon reviewing your website, I noticed that the price of the ${row.product_name} has been reduced to $${row.last_checked_price} from the original price of $${row.unit_price} since my purchase. As per your price adjustment policy, I would like to request a refund for the difference in price.

I understand that according to your policy, customers are eligible for a refund of the price difference within a certain timeframe. As I fall within this timeframe, I kindly request that you process the refund to the original payment method used for the purchase.

Please let me know if you require any further information or documentation from my end to facilitate this process. I appreciate your prompt attention to this matter and look forward to hearing back from you soon regarding the status of my refund.

Thank you for your assistance.

Warm regards,
my_name`);
    setShowSendEmailModal(true);
  };

  const handleSendEmailSubmit = async () => {
    if (emailTitle === "" || emailBody === "" || emailRecipient === "") {
      toast(`At least one of the fields is empty`);
      return;
    }
    const sendNotification = httpsCallable(functions, "send_notification_func_trigger");
    sendNotification({
        "email": emailRecipient,
        "subject": emailTitle,
        "content": emailBody,
    })

    setShowSendEmailModal(false);
    setEmailTitle("");
    setEmailBody("");
    setEmailRecipient("");
  };

  const handleRefundTrackerSubmit = async (refundTrackerObj) => {
    currentRow.refundTracker = refundTrackerObj;
    currentRow.refundTrackerID = adminIdToName(refundTrackerObj.admin_id) + " (" + refundTrackerObj.state + ")";
    updateRefundTrackerByProductIDAdminID(currentRow.docRef, refundTrackerObj);
    setCurrentRow(null);
    setShowRefundTrackerModal(false);
    reset();
  };

  const handleOperationLog = async (inRow, inType, inOldValue, inNewValue) => {
    var entityId = null;
    if (currentRow != null) {
      entityId = currentRow.docRef;
    } else if (inRow != null) {
      entityId = inRow.docRef;
    }
    createOperationLogs(entityId, "parsed_product", adminId, inType, inOldValue, inNewValue);
  };

  const reset = () => {
    setSeed(Math.random());
  }

  return (
    <>
      <div id="loader"></div>
      <main>
        <AdminLightSidebar>
          <div className="relative pb-20 sm:py-5">
            <Container className="relative lg:pt-8">
              {" "}
              <Header title={"Official Refunds"} content={"Lists official refunds only. (title_score > 0.8, from unbanned sellers) Remember to view products in cognito mode."} />{" "}
            </Container>
            <Container className="relative lg:pt-8" key={seed}>
              {" "}
              <Table
                idField="id"
                rows={refunds}
                columns={[
                  {
                    attribute: "email",
                    attributeName: "email",
                    width: 10,
                  },
                  {
                    attribute: "product_name",
                    attributeName: "receipt title",
                    width: 50,
                  },
                  {
                    attribute: "receipt_time",
                    attributeName: "receipt time",
                    width: 10,
                  },
                  {
                    attribute: "email_time",
                    attributeName: "email time",
                    width: 10,
                  },
                  {
                    attribute: "spec",
                    attributeName: "spec",
                    width: 20,
                  },
                  {
                    attribute: "crawled_product_title",
                    attributeName: "matched title",
                    width: 50,
                  },
                  {
                    attribute: "title_score",
                    attributeName: "title similarity",
                    width: 10,
                  },
                  {
                    attribute: "seller_name",
                    attributeName: "retailer",
                    width: 20,
                    onClick: (row) => {loadSendEmailModal(row);},
                  },
                  {
                    attribute: "link",
                    attributeName: "link",
                    width: 20,
                    onClick: (row) => {window.open(row.product_link, '_blank').focus();},
                  },
                  {
                    attribute: "unit_price",
                    attributeName: "purchased price",
                    width: 5,
                  },
                  {
                    attribute: "last_checked_price",
                    attributeName: "current price",
                    width: 5,
                  },
                  {
                    attribute: "source",
                    attributeName: "source",
                    width: 5,
                  },
                  {
                    attribute: "status",
                    attributeName: "status",
                    width: 20,
                    type: "RefundStatusDropdown",
                    onClick: (row, val, idx) => {
                      row.status = val;
                      if (val === "Available") {
                        val = "refund-available";
                      } else if (val === "Pending") {
                        val = "refund-pending";
                      } else if (val === "Completed") {
                        val = "refund-complete";
                      } else if (val === "Active") {
                        val = "active";
                      } else if (val === "Need Attention") {
                        val = "need-attention";
                      } else if (val === "Invalid") {
                        val = "invalid";
                      }
                      editRefundAndPapProductStatus(row.docRef, val);
                      handleOperationLog(row, "status_change", null, val);
                      setRefunds(refunds);
                      reset();
                    },
                  },
                  {
                    attribute: "refundTrackerID",
                    attributeName: "refundTracker",
                    width: 20,
                    onClick: (row, index) => {
                      setCurrentRefundTrackerObj(row.refundTracker);
                      setCurrentRow(row);
                      setShowRefundTrackerModal(true);
                    },
                  },
                  {
                    attribute: "docRef",
                    attributeName: "id",
                    width: 5,
                    onClick: (row) => {window.open("/view-email/" + row.docRef.substring(0, row.docRef.lastIndexOf('_')), '_blank').focus();},
                  },
                ]}
              />{" "}
            </Container>
          </div>
          <Footer companyName="pap!" />
        </AdminLightSidebar>
        <SendEmailModal
          emailTitle={emailTitle}
          setEmailTitle={setEmailTitle}
          emailBody={emailBody}
          setEmailBody={setEmailBody}
          emailRecipient={emailRecipient}
          setEmailRecipient={setEmailRecipient}
          handleSubmit={handleSendEmailSubmit}
          show={showSendEmailModal}
          setShow={setShowSendEmailModal}
        />
        <RefundTrackerModal
          refundTrackerObj={currentRefundTrackerObj}
          setRefundTrackerObj={setCurrentRefundTrackerObj}
          handleSubmit={handleRefundTrackerSubmit}
          handleOperationLog={handleOperationLog}
          adminId={adminId}
          show={showRefundTrackerModal}
          setShow={setShowRefundTrackerModal}
        />
      </main>
    </>
  );
}

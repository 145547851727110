import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import * as Heroicons from '@heroicons/react/24/outline'
import { signOut } from 'firebase/auth';
import { auth } from 'services/firebase';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function AdminLightSidebar(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navigation = [
    { name: 'All Users', href: "/admin-users", icon: Heroicons.UserGroupIcon, current: false },
    { name: 'Search', href: "/admin-search", icon: Heroicons.MagnifyingGlassIcon, current: false },
    { name: 'Official Refunds', href: "/admin-official-refunds", icon: Heroicons.ReceiptPercentIcon, current: false },
    { name: 'All Refunds', href: "/admin-refunds", icon: Heroicons.ReceiptPercentIcon, current: false },
    { name: 'All Products', href: "/admin-products", icon: Heroicons.ShoppingBagIcon, current: false },
    { name: 'All Emails', href: "/admin-emails", icon: Heroicons.InboxIcon, current: false },
    { name: 'User Stats', href: "/admin-user-stats", icon: Heroicons.VariableIcon, current: false },
  ];

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <Heroicons.XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className={classNames(
                    "flex-1 overflow-y-auto pt-5 pb-4",
                    props.bgColor ? props.bgColor : "bg-white"
                  )} >
                    <a href="/">
                      <div className="flex flex-shrink-0 items-center px-4">
                        <img
                          className="h-8 w-auto"
                          src="https://shop.raceya.fit/wp-content/uploads/2020/11/logo-placeholder.jpg"
                        />
                      </div>
                    </a>
                    <nav className="mt-5 space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            props.theme === "dark" ? (
                              item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-800 hover:text-gray-100'
                            ) : (
                              item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                            ),

                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                  <div className={classNames(
                    "flex flex-shrink-0 p-4",
                    props.bgColor ? props.bgColor : "bg-white"
                  )}>
                    <div className="group block w-full flex-shrink-0">
                      <div className="flex items-center">
                        <div className="ml-3">
                          <p className={classNames(
                            props.theme === "dark" ? (
                              "text-white group-hover:text-gray-100"
                            ) : (
                              "text-gray-700 group-hover:text-gray-900"
                            ),
                            "text-base font-medium",
                          )}>Sign Out</p>
                        </div>
                        <div className="items-end ml-auto">
                          <Heroicons.ArrowRightStartOnRectangleIcon
                            className="h-6 w-6 cursor-pointer text-gray-400 hover:text-gray-500"
                            onClick={
                              async () => {
                                await signOut(auth);
                                window.location.href = "/sign-in";
                              }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col z-10">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className={classNames(
            "flex min-h-0 flex-1 flex-col border-r",
            props.bgColor ? props.bgColor : "bg-white"
          )}>
            <div className="flex flex-1 flex-col overflow-y-auto pt-8 pb-4">
              <a href="/admin-search">
                <div className="flex flex-shrink-0 items-center justify-center px-4">
                  <img
                    className="h-12 w-auto"
                    src="https://shop.raceya.fit/wp-content/uploads/2020/11/logo-placeholder.jpg"
                  />
                </div>
              </a>
              <nav className={
                `mt-5 flex-1 space-y-1 px-2
                ${props.bgColor ? props.bgColor : "bg-white"}
                `}>
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      props.theme === "dark" ? (
                        item.current ? 'bg-gray-900 text-gray-100' : 'text-gray-300 hover:bg-gray-800 hover:text-gray-100'
                      ) : (
                        item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                      ),

                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        props.theme === "dark" ? (
                          item.current ? 'bg-gray-900 text-gray-100' : 'text-gray-300 hover:bg-gray-800 hover:text-gray-100'
                        ) : (
                          item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                        ),
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
            <div className="flex flex-shrink-0 p-4">
              <div className="group block w-full flex-shrink-0">
                <div className="flex items-center">
                  <div className="ml-3">
                    <p className={classNames(
                      props.theme === "dark" ? (
                        "text-gray-200 group-hover:text-gray-100"
                      ) : (
                        "text-gray-700 group-hover:text-gray-900"
                      ),
                      "text-sm font-medium"
                    )}>Sign Out</p>

                  </div>
                  <div className="items-end ml-auto">
                    <Heroicons.ArrowRightStartOnRectangleIcon
                      className="h-6 w-6 cursor-pointer text-gray-400 hover:text-gray-500"
                      onClick={
                        async () => {
                          await signOut(auth);
                          window.location.href = "/sign-in";
                        }
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex border-1 bg-gray-100 h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Heroicons.Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">
            <div className="py-6">
              <div className="mx-auto max-w-full px-4 sm:px-6 md:px-8">
                {/* Replace with your content */}
                <div className="py-4">
                  {props.children}
                </div>
                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

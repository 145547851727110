import { Footer } from "components/Footer";
import { Container } from "components/Container";
import { StatsWithSharedBorders } from "components/StatsWithSharedBorders";
import { Table } from "components/Table";
import { Header } from "components/Header";
import { getProductsByEmail, getUserById } from "services/firebase";
import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "services/firebase";

import toast from "react-hot-toast";
import { LightSidebar } from "components/LightSidebar";

export function Dashboard() {
  const [currentUser, setCurrentUser] = useState(null);
  const [ongoingCnt, setOngoingCnt] = useState(0);
  const [refundCnt, setRefundCnt] = useState(0);
  const [refundTotal, setRefundTotal] = useState(0);
  const [products, setProducts] = useState([]);

  const loadAuthPage = (email) => {
    // TODO: check state
    const state = (Math.random() + 1).toString(36).substring(2);
    const authPageUrl = "https://accounts.google.com/o/oauth2/auth?client_id=69825809285-j0hs7rhus3oougluep0ns6jcqub8nkq8.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fportal.joinpap.com%2Fgoogle_oauth_callback_v2&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&access_type=offline&response_type=code&approval_prompt=force&user_id=" + email + "&state=" + state;
    window.open(authPageUrl, '_blank').focus();
  };

  // TODO: let user manually edit product title & matching product page
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        toast.error(`Please login first to access data.`);
        window.location = "/one-click-sign-in";
        return;
      }
      getUserById(user.uid).then((fetchedUser) => {
        setCurrentUser(fetchedUser);
        if (fetchedUser.status === "new") {
          toast((t) => (
            <span>
              <p>Your email account hasn't provided authorization to track receipts yet.</p>
              <p class="mt-3">Please head to the settings page or click the button below to authorize pap! to access your receipts.</p>
              <button type="auth"
                class="mt-3 inline-flex rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => loadAuthPage(fetchedUser.email)}>Authorize</button>
            </span>
          ),{
            duration: 10000,
          });
        }
      });
      getProductsByEmail(user.email).then((fetchedProducts) => {
        let pendingCnt = 0;
        let priceTotal = 0;
        if (fetchedProducts === null) {
          return;
        }
        let refundProducts = fetchedProducts.filter((product) => {
          if (product.color !== "" && product.color !== "NA") {
            product.product_name += " " + product.color;
          }
          if (product.size !== "" && product.size !== "NA") {
            product.product_name += " " + product.size;
          }
          if (product.dimensions !== "" && product.dimensions !== "NA") {
            product.product_name += " " + product.dimensions;
          }

          if (product.status === "refund-available") {
            product.status = "Available";
            pendingCnt += 1;
            return true;
          } else if (product.status === "refund-pending") {
            product.status = "Pending";
            pendingCnt += 1;
            return true;
          } else if (product.status === "refund-complete") {
            product.status = "Completed";
            priceTotal += product.unit_price;
            return true;
          }
          return false;
        })
        if (!refundProducts) {
          return;
        }
        setRefundTotal(priceTotal);
        setOngoingCnt(pendingCnt);
        setRefundCnt(refundProducts.length);
        setProducts(refundProducts);
      });
    });
  }, []);

  return (
    <>
      <div id="loader"></div>
      <main>
        <LightSidebar>
          <div className="relative pb-20 sm:py-5">
            <Container className="relative lg:pt-8">
              {" "}
              <Header title={"Dashboard"} content={""} />{" "}
            </Container>
            <Container className="relative lg:pt-8">
              <StatsWithSharedBorders
                heading="Last 30 days"
                stats={[
                  {
                    name: "Total refunds issued",
                    stat: refundCnt,
                  },
                  {
                    name: "Total money refunded",
                    stat: "$" + refundTotal,
                  },
                  {
                    name: "Ongoing refund requests",
                    stat: ongoingCnt,
                  },
                ]}
              />
            </Container>
            <Container className="relative lg:pt-8">
              {" "}
              <Table
                idField="id"
                title="Refunds"
                description="All pending or completed refunds."
                rows={products}
                columns={[
                  {
                    attribute: "id",
                    attributeName: "id",
                    width: 10,
                  },
                  {
                    attribute: "crawled_product_title",
                    attributeName: "item",
                    width: 20,
                  },
                  {
                    attribute: "seller_name",
                    attributeName: "retailer",
                    width: 20,
                  },
                  {
                    attribute: "unit_price",
                    attributeName: "purchased price",
                    width: 5,
                  },
                  {
                    attribute: "last_checked_price",
                    attributeName: "current price",
                    width: 5,
                  },
                  {
                    attribute: "status",
                    attributeName: "refund status",
                    width: 20,
                  },
                ]}
              />{" "}
            </Container>
          </div>
          <Footer companyName="pap!" />
        </LightSidebar>
      </main>
    </>
  );
}
import { Footer } from "components/Footer";
import { Container } from "components/Container";
import { Table } from "components/Table";
import { Header } from "components/Header";
import { StatsWithSharedBorders } from "components/StatsWithSharedBorders";
import { getAllEmails, getUserById, getAllEmailsWithPagination,
  getEmailsByStatusWithPagination, getEmailsByEmailAddressWithPagination,
  editEmailStatus, getEmailsByEmailAddressStatusWithPagination,
  getEmailCountByStatus } from "services/firebase";
import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "services/firebase";
import { formatDate, daysAgo } from "utils/time";

import toast from "react-hot-toast";
import { AdminLightSidebar } from "components/AdminLightSidebar";

export function AdminEmails() {
  const initPageSize = 20;
  const pageSize = 40;
  const [seed, setSeed] = useState(1);
  const [emails, setEmails] = useState([]);
  const [lastEmail, setLastEmail] = useState(null);
  const [searchEmail, setSearchEmail] = useState("");
  const [searchEmailInput, setSearchEmailInput] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [newCnt, setNewCnt] = useState(0);
  const [processedCnt, setProcessedCnt] = useState(0);
  const [errorCnt, setErrorCnt] = useState(0);

  useEffect(() => onAuthStateChanged(auth, (user) => {
    if (!user) {
      toast.error(`Please login first to access data.`);
      // redirect
      window.location = "/sign-in";
      return;
    }
    getUserById(user.uid).then((user) => {
      if (!user.is_admin) {
        toast.error(`You are not authorized to access this page.`);
        window.location = "/";
        return;
      }
    });
    getEmailCountByStatus().then((emailCnts) => {
      setNewCnt(emailCnts.new);
      setProcessedCnt(emailCnts.processed);
      setErrorCnt(emailCnts.error);
    });
    getAllEmailsWithPagination(initPageSize, lastEmail).then((fetchedEmails) => {
      setEmails(processFetchedEmails(fetchedEmails));
    });
  }), []);

  const processFetchedEmails = (fetchedEmails) => {
    if (!fetchedEmails || fetchedEmails.length === 0) {
      toast(`No more emails.`);
      return [];
    }
    setLastEmail(fetchedEmails[fetchedEmails.length-1]);
    fetchedEmails = fetchedEmails.map(doc =>({docRef: doc.ref.id, ...doc.data()}));
    fetchedEmails.map((email) => {
      if (email.status === "new") {
        email.status = "New";
        email.backgroundColor = "#0080003d";
      } else if (email.status === "processed") {
        email.status = "Processed";
      } else if (email.status === "empty-email") {
        email.status = "Blank Email";
      } else if (email.status === "error-empty") {
        email.status = "No Product";
      } else if (email.status === "error-no-price") {
        email.status = "No Price";
      } else if (email.status === "error-not-receipt") {
        email.status = "Not Receipt";
      } else if (email.status === "error-unknown") {
        email.status = "Unknown Error";
        email.backgroundColor = "#8019003d";
      }

      if (email.date === undefined) {
        email.date = "";
      } else {
        var dateObj = new Date(email.date);
        email.date = formatDate(dateObj) + " (" + daysAgo(dateObj) + ")";
      }

      if (email.source === "gmail") {
        email.source = "Gmail";
      } else if (email.source === "manual") {
        email.source = "Manual";
      }
    });
    return fetchedEmails;
  };

  const loadMore = () => {
    if (searchEmail === "" && statusFilter === "") {
      getAllEmailsWithPagination(pageSize, lastEmail).then((fetchedEmails) => {
        const loadedEmails = processFetchedEmails(fetchedEmails);
        setEmails(emails.concat(loadedEmails));
      });
    } else if (searchEmail === "") {
      getEmailsByStatusWithPagination(statusFilter, pageSize, lastEmail).then((fetchedEmails) => {
        const loadedEmails = processFetchedEmails(fetchedEmails);
        setEmails(emails.concat(loadedEmails));
      });
    } else if (statusFilter === "") {
      getEmailsByEmailAddressWithPagination(searchEmail, pageSize, lastEmail).then((fetchedEmails) => {
        const loadedEmails = processFetchedEmails(fetchedEmails);
        setEmails(emails.concat(loadedEmails));
      });
    } else {
      getEmailsByEmailAddressStatusWithPagination(searchEmail, statusFilter, pageSize, lastEmail).then((fetchedEmails) => {
        const loadedEmails = processFetchedEmails(fetchedEmails);
        setEmails(emails.concat(loadedEmails));
      });
    }
  };

  const submitEmailSearch = () => {
    if (searchEmailInput === searchEmail) {
      return;
    }
    setSearchEmail(searchEmailInput);
    reloadEmails(searchEmailInput, statusFilter);
  };

  const handleFilterChange = (newStatusFilter) => {
    if (newStatusFilter === statusFilter) {
      return;
    }
    setStatusFilter(newStatusFilter);
    reloadEmails(searchEmail, newStatusFilter);
  };

  const reloadEmails = (inEmail, inStatus) => {
    if (inEmail === "" && inStatus === "") {
      getAllEmailsWithPagination(pageSize, null).then((fetchedEmails) => {
        setEmails(processFetchedEmails(fetchedEmails));
      });
    } else if (inEmail === "") {
      getEmailsByStatusWithPagination(inStatus, pageSize, null).then((fetchedEmails) => {
        setEmails(processFetchedEmails(fetchedEmails));
      });
    } else if (inStatus === "") {
      getEmailsByEmailAddressWithPagination(inEmail, pageSize, null).then((fetchedEmails) => {
        setEmails(processFetchedEmails(fetchedEmails));
      });
    } else {
      getEmailsByEmailAddressStatusWithPagination(inEmail, inStatus, pageSize, null).then((fetchedEmails) => {
        setEmails(processFetchedEmails(fetchedEmails));
      });
    }
  };

  const reset = () => {
    setSeed(Math.random());
  }

  return (
    <>
      <div id="loader"></div>
      <main>
        <AdminLightSidebar>
          <div className="relative pb-20 sm:py-5">
            <Container className="relative lg:pt-8">
              {" "}
              <Header title={"All Emails"} content={"Lists all emails. Processed count consists of processed, error-no-price, and error-empty. Errors consists of empty-email and error-unknown."} />{" "}
            </Container>
            <Container className="relative lg:pt-2" key={seed}>
              <StatsWithSharedBorders
                stats={[
                  {
                    name: "New Emails",
                    stat: newCnt,
                  },
                  {
                    name: "Processed",
                    stat: processedCnt,
                  },
                  {
                    name: "Errors",
                    stat: errorCnt,
                  }
                ]}
              />
            </Container>
            <input
              type="email" placeholder="Email" value={searchEmailInput} onChange={(e) => setSearchEmailInput(e.target.value)}
              className="ml-8 mt-4 w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
            <button type="auth" class="mt-4 ml-3 inline-flex rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={submitEmailSearch}>Submit</button>
            <label className="pl-10 pr-3" htmlFor="statusFilter">Status:</label>
            <select className="rounded" id="statusFilter" value={statusFilter} onChange={(e) => handleFilterChange(e.target.value)}>
                <option value="">All</option>
                <option value="new">New</option>
                <option value="processed">Processed</option>
                <option value="empty-email">Blank Email</option>
                <option value="error-empty">No Product</option>
                <option value="error-no-price">No Price</option>
                <option value="error-not-receipt">Not Receipt</option>
                <option value="error-unknown">Unknown Error</option>
            </select>
            <Container className="relative">
              {" "}
              <Table
                idField="id"
                rows={emails}
                columns={[
                  {
                    attribute: "docRef",
                    attributeName: "id",
                    width: 5,
                    onClick: (row) => {window.open("/view-email/" + row.docRef, '_blank').focus();},
                  },
                  {
                    attribute: "date",
                    attributeName: "sent time",
                    width: 5,
                  },
                  {
                    attribute: "sender",
                    attributeName: "sender",
                    width: 5,
                  },
                  {
                    attribute: "source_email",
                    attributeName: "to",
                    width: 5,
                  },
                  {
                    attribute: "source",
                    attributeName: "source",
                    width: 5,
                  },
                  {
                    attribute: "status",
                    attributeName: "status",
                    width: 20,
                    type: "EmailStatusDropdown",
                    onClick: (row, val, index) => {
                      row.status = val;
                      if (val === "New") {
                        val = "new";
                      } else if (val === "Processed") {
                        val = "processed";
                      } else if (val === "Blank Email") {
                        val = "empty-email";
                      } else if (val === "No Product") {
                        val = "error-empty";
                      } else if (val === "No Price") {
                        val = "error-no-price";
                      } else if (val === "Not Receipt") {
                        val = "error-not-receipt";
                      } else if (val === "Unknown Error") {
                        val = "error-unknown";
                      }
                      editEmailStatus(row.docRef, val);
                      reset();
                    },
                  },
                  {
                    attribute: "snippet",
                    attributeName: "snippet",
                    width: 50,
                  }
                ]}
              />{" "}
            </Container>
          </div>
          <button type="auth" class="ml-8 inline-flex rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={loadMore}>Load More</button>
          <Footer companyName="pap!" />
        </AdminLightSidebar>
      </main>
    </>
  );
}

import { Footer } from "components/Footer";
import { Container } from "components/Container";
import { Table } from "components/Table";
import { Header } from "components/Header";
import { getAllUsers, getUserById } from "services/firebase";
import { AdminLightSidebar } from "components/AdminLightSidebar";
import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from 'services/firebase';

import toast from "react-hot-toast";

export function AdminUserStats() {
  const [userStats, setUserStats] = useState([]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        toast.error(`Please login first to access data.`);
        window.location="/admin-sign-in";
        return;
      }
      getUserById(user.uid).then((user) => {
        if (!user.is_admin) {
          toast.error(`You are not authorized to access this page.`);
          window.location="/";
          return;
        }
      });
      getAllUsers().then((fetchedUsers) => {
        if (!fetchedUsers) {
          toast.error(`Cannot find the Users profiles.`);
          return;
        }
        setUserStats(fetchedUsers);
      });
    });
    
  }, []);

  return (
    <>
      <div id="loader"></div>
      <main>
        <AdminLightSidebar>
          <div className="relative pb-20 sm:py-5">
            <Container className="relative lg:pt-8">
              {" "}
              <Header title={"User Stats"} content={"Page still under construction"} />{" "}
            </Container>
            <Container className="relative lg:pt-8">
              {" "}
              <Table
                idField="id"
                rows={userStats}
                columns={[
                  {
                    attribute: "id",
                    attributeName: "User ID",
                    width: 10,
                  },
                  {
                    attribute: "name",
                    attributeName: "Name",
                    width: 10,
                  },
                  {
                    attribute: "is_admin",
                    attributeName: "Admin",
                    width: 10,
                  },
                  {
                    attribute: "email",
                    attributeName: "Email",
                    width: 10,
                  },
                ]}
              />{" "}
            </Container>
          </div>
          <Footer companyName="pap!" />
        </AdminLightSidebar>
      </main>
    </>
  );
}

import React, { useState } from "react";
import styled, { keyframes } from 'styled-components'

const useLoadingSpinner = () => {
  const [loading, setLoading] = useState(false);
  return [
    loading ? <Loader /> : null,
    () => setLoading(true),
    () => setLoading(false),
  ];
};

const Loader = () => {
  return (
    <LoaderContainer>
      <Spinner />
    </LoaderContainer>
  );
};

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;


const Spinner = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border: .25em solid currentColor;
  border-radius: 50%;
  border-right-color: transparent;
  animation: ${spin} 0.75s linear infinite;
`;

const LoaderContainer = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(255,255,255,0.5);
  z-index: 100;
`;

export { useLoadingSpinner };

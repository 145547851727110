import { Footer } from "components/Footer";
import { Container } from "components/Container";
import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';

import toast from "react-hot-toast";

export function UnsupportedBrowser() {
  const [referrerCode, setReferrerCode] = useState("");
  const [showContent, setShowContent] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [browserName, setBrowserName] = useState("Instagram");

  useEffect(() => {
    if (!navigator.userAgent.includes("Instagram") && !navigator.userAgent.includes("WeChat") && !navigator.userAgent.includes("Wechat") && !navigator.userAgent.includes("LinkedInApp")) {
      var referrerVal = "instagram";
      if (searchParams.get("referrer")) {
        referrerVal = searchParams.get("referrer");
      }
      window.location.href = "https://www.joinpap.com?referrer=" + referrerVal;
      return;
    }

    if (navigator.userAgent.includes("WeChat") || navigator.userAgent.includes("Wechat")) {
      setBrowserName("WeChat");
    } else if (navigator.userAgent.includes("LinkedInApp")) {
      setBrowserName("LinkedIn");
    }
    setShowContent(true);
  }, []);

  return (
    <>
      <div id="loader"></div>
      {showContent && (
      <main>
        <div className="relative">
          <Container className="flex w-4/5 h-screen justify-center mt-5">
            <div className="block">
              <div className="flex items-center justify-center">
                <div style={{"max-width": "80px"}}>
                  <img height="auto" src="pap_logo_256.png"/>
                </div>
              </div>
              <div className="flex justify-center">
                <p className="mt-1 text-center max-w-4xl text-4xl leading-9 font-medium text-center text-gray-800 pap-font">{browserName}{"'s browser sucks."}</p>
              </div>
              <div className="flex justify-center">
                <p className="mt-3 text-center max-w-4xl text-4xl leading-9 font-medium text-center text-gray-800 pap-font">{"Just open this link in your Safari or Chrome app by..."}</p>
              </div>
              <div className="flex mt-10">
                <div class="mr-2 flex min-h-6 min-w-6 max-h-6 max-w-6 items-center justify-center rounded-full bg-yellow-300">
                  <p className="text-md pap-font">1</p>
                </div>
                <p className="text-xl leading-6 text-neutral-700 pap-font">{"Clicking on the 3 dots on the top right corner of this page"}</p>
              </div>
              <div className="flex mt-2">
                <div class="mr-2 flex min-h-6 min-w-6 max-h-6 max-w-6 items-center justify-center rounded-full bg-yellow-300">
                  <p className="text-md pap-font">2</p>
                </div>
                <p className="text-xl leading-6 text-neutral-700 pap-font">{"Clicking on \"Open in external browser\""}</p>
              </div>
            </div>
          </Container>
        </div>
      </main>
      )}
    </>
  );
}

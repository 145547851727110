import React, { useEffect, useState } from "react";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Footer } from "components/Footer";
import { Container } from "components/Container";


export default function TravelArrowCheckoutForm() {
  const getWindowSize = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  };

  const stripe = useStripe();
  const elements = useElements();
  const [windowDimensions, setWindowDimensions] = useState(getWindowSize());
  const [emailAddress, setEmailAddress] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const emailAddress = new URLSearchParams(window.location.search).get(
      "email"
    );
    setEmailAddress(emailAddress);
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setLoading(false);
    } else {
      setError(null);
      window.location.href = "https://portal.joinpap.com/stripe_payment_setup?email=" + emailAddress + "&pm_id=" + paymentMethod.id;
    }
  };

  return (
    <div className="fixed inset-0 flex h-screen mx-auto max-w-full items-center justify-center tw-anti-aliased">
      <div className={`h-[400px] w-[384px] flex items-center transition-all transform bg-white rounded-lg ${windowDimensions.width > 600 ? 'border':''}`}>
        <div className="p-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img
                src="https://www.travelarrow.io/_next/image?url=https%3A%2F%2Fcdn.travelarrow.io%2Flogo.png&w=256&q=75"
                alt="travel arrow logo"
                className="w-6 h-6 mr-1"
              />
              &times;
              <img
                src="https://cdn.prod.website-files.com/661aaea740edaca5a82103d3/662314e5432ba73de164b660_pap!.svg"
                alt="pap logo"
                className="w-8 h-8 ml-1"
              />
            </div>
          </div>
          <div className="mt-2">
            <hr className="my-3 border-gray-200 border-t-1" />
            <div className="m-1 mb-4">
              <p className="text-lg font-bold text-left text-zinc-700">
                Payment Method Setup
              </p>
              <p className="text-sm font-light text-left text-zinc-500">
                Enter your credit card info to get paid directly
              </p>
            </div>
            <div className="mx-4 mb-6 border rounded-md">
              <CardElement
                className="m-4"
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                }}/>
            </div>
            <button
              className="w-full mt-2 px-3 py-2 text-white rounded-lg ta-bg-pink focus:outline-none"
              onClick={() => {handleSubmit();}}
              disabled={!stripe || loading}
            >
              {loading ? 'Processing...' : 'Get Paid'}
            </button>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            <div className="mt-4 bottom-0 right-0 text-sm text-gray-400">
              <a dir="rtl" href="https://stripe.com" target="_blank" rel="noopener noreferrer">
                <div className="flex">
                  <svg className="w-10 h-6 ml-1 fill-stripe-color" xmlns="http://www.w3.org/2000/svg" viewBox="0 40 640 400"><path d="M165 144.7l-43.3 9.2-.2 142.4c0 26.3 19.8 43.3 46.1 43.3 14.6 0 25.3-2.7 31.2-5.9v-33.8c-5.7 2.3-33.7 10.5-33.7-15.7V221h33.7v-37.8h-33.7zm89.1 51.6l-2.7-13.1H213v153.2h44.3V233.3c10.5-13.8 28.2-11.1 33.9-9.3v-40.8c-6-2.1-26.7-6-37.1 13.1zm92.3-72.3l-44.6 9.5v36.2l44.6-9.5zM44.9 228.3c0-6.9 5.8-9.6 15.1-9.7 13.5 0 30.7 4.1 44.2 11.4v-41.8c-14.7-5.8-29.4-8.1-44.1-8.1-36 0-60 18.8-60 50.2 0 49.2 67.5 41.2 67.5 62.4 0 8.2-7.1 10.9-17 10.9-14.7 0-33.7-6.1-48.6-14.2v40c16.5 7.1 33.2 10.1 48.5 10.1 36.9 0 62.3-15.8 62.3-47.8 0-52.9-67.9-43.4-67.9-63.4zM640 261.6c0-45.5-22-81.4-64.2-81.4s-67.9 35.9-67.9 81.1c0 53.5 30.3 78.2 73.5 78.2 21.2 0 37.1-4.8 49.2-11.5v-33.4c-12.1 6.1-26 9.8-43.6 9.8-17.3 0-32.5-6.1-34.5-26.9h86.9c.2-2.3 .6-11.6 .6-15.9zm-87.9-16.8c0-20 12.3-28.4 23.4-28.4 10.9 0 22.5 8.4 22.5 28.4zm-112.9-64.6c-17.4 0-28.6 8.2-34.8 13.9l-2.3-11H363v204.8l44.4-9.4 .1-50.2c6.4 4.7 15.9 11.2 31.4 11.2 31.8 0 60.8-23.2 60.8-79.6 .1-51.6-29.3-79.7-60.5-79.7zm-10.6 122.5c-10.4 0-16.6-3.8-20.9-8.4l-.3-66c4.6-5.1 11-8.8 21.2-8.8 16.2 0 27.4 18.2 27.4 41.4 .1 23.9-10.9 41.8-27.4 41.8zm-126.7 33.7h44.6V183.2h-44.6z"/></svg>
                  Powered by
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { PaperClipIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import { StoredFile, StoredFileState } from 'data/common'
import { uploadFile } from 'services/firebase'


export class FileUploadProp {
  initialFiles: [StoredFile];
  fieldName: String;
  fieldDisplayName: String;
  entityId: String;
  supportedFileTypes: String;
}

export function FileUpload(props: FileUploadProp) {
  const { initialFiles, fieldName, fieldDisplayName, entityId, supportedFileTypes } = props;
  const navigate = useNavigate();

  const [filePendingUpload, setFilePendingUpload] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [files, setFiles] = useState(initialFiles);

  useEffect(() => {
    setFiles(initialFiles);
  }, [initialFiles]);

  const uploadFileToFirestore = (fileToUpload) => {
    uploadFile(
      entityId,
      fieldName,
      fileToUpload,
      setUploadProgress,
      (file: StoredFile) => {
        const allFiles = files.concat(file);
        setFiles([...new Map(allFiles.map(f => [f.filename, f])).values()]);
        setFilePendingUpload(null);
        setUploadProgress(null);
      }
    )
  };

  const filePendingUploadEntry = filePendingUpload && (
    <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
      <div className="flex w-0 flex-1 items-center">
        <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
        <span className="ml-2 w-0 text-gray-800 flex-1 truncate">{filePendingUpload.name}</span>
      </div>
      <div className="ml-4 flex-shrink-0 cursor-pointer">
        {
          uploadProgress != null ?
            (
              <span className="ml-2 w-0 text-gray-800 flex-1 truncate">{`${uploadProgress}%`}</span>
            ) : (
              <a
                onClick={() => uploadFileToFirestore(filePendingUpload)}
                className="font-medium text-blue-600 hover:text-blue-500"
              >
                Upload
              </a>
            )
        }
      </div>
    </li>
  );
  const existingFileEntries = (
    files.flatMap((file: StoredFile) => (
      file.state === StoredFileState.Deleted ? [] : (
        <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
          <div className="flex w-0 flex-1 items-center">
            <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            <span className="ml-2 w-0 text-indigo-600 flex-1 truncate cursor-pointer">{file.filename}</span>
          </div>
          <div className="ml-4 flex-shrink-0">
            <a
              onClick={() => {
                const removedFileIdx = files.map(f => f.filename).indexOf(file.filename);
                if (removedFileIdx < 0) return;
                const deletedFile = files[removedFileIdx];
                files.splice(removedFileIdx, 1);
                deletedFile.state = StoredFileState.Deleted;
                setFiles(files.concat(deletedFile));
              }}
              className="font-medium text-red-600 hover:text-red-500 cursor-pointer"
            >
              Remove
            </a>
          </div>
        </li>
      )
    ))
  );

  return (
    <>
      <div className="sm:col-span-6">
        <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-700">
          {fieldDisplayName}
        </label>
        <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="entity_file_upload"
                className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>Select a file</span>
                <input id="entity_file_upload"
                  name="entity-file-upload"
                  type="file"
                  className="sr-only"
                  accept={supportedFileTypes}
                  onClick={(event) => {
                    event.target.value = null
                  }}
                  onChange={(event) => {
                    console.log(123213213);
                    if (!event.target.files || !event.target.files[0]) return;
                    if (event.target.files[0].size > 50900000) {
                      toast.error("Please upload file under 50MB.");
                      return;
                    }
                    setFilePendingUpload(event.target.files[0]);
                    console.log(123213213);
                    uploadFileToFirestore(event.target.files[0]);
                  }}
                />
              </label>
              <p className="pl-1"> to upload</p>
            </div>
            <p className="text-xs text-gray-500">{supportedFileTypes + " up to 10MB"}</p>
          </div>
        </div>
      </div>
      <div className="sm:col-span-4">
        <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
          {filePendingUploadEntry}
          {existingFileEntries}
        </ul>
      </div>
    </>
  )
}

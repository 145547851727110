import { Footer } from "components/Footer";
import { Container } from "components/Container";
import React, { useState, useEffect } from "react";

import toast from "react-hot-toast";

export function GetUserAgent() {
  const [content, setContent] = useState("");

  useEffect(() => {
    setContent(navigator.userAgent + " " +
    (navigator.userAgent.includes("iPhone")).toString() + " " +
    (navigator.userAgent.includes("Instagram")).toString() + " " +
    (!navigator.userAgent.includes("Instagram") && !navigator.userAgent.includes("WeChat") && !navigator.userAgent.includes("Wechat")).toString());
  }, []);

  return (
    <>
      <div id="loader"></div>
      <main>
        <div className="relative">
          <Container className="flex w-4/5 h-screen justify-center mt-5">
            <div className="flex justify-center">
              <p className="mt-1 text-center max-w-4xl text-xl leading-9 font-medium text-center text-gray-800 pap-font">{content}</p>
            </div>
          </Container>
        </div>
      </main>
      )}
    </>
  );
}
